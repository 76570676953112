import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import RecommendationsList from "../../Components/Task/Recommendations/RecommendationsList";
import { setAuthAction, startLoading, doneLoading } from "../../Redux/actions/index";
import Select from "react-select";
import SocketContext from "../../Utils/socket-context";
import api from "../../Utils/Api";
import Pagination from "../../Utils/Pagination";
import Constants from "../../Utils/Constants";
import { buildFilters } from "../../Utils/Helpers";
import Restricted from "../../Utils/Restricted";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlus,faFileExport,} from "@fortawesome/free-solid-svg-icons";
import AddNewRecommendationOverlay from "../../Components/Task/Recommendations/AddNewRecommendationOverlay";
import { Input } from "react-select/lib/animated";

const mapStateToProps = state => {
  return {
    session: state.session,
    filters: state.filters
  };
};

function mapDispatchToProps(dispatch) {
  return {
    isLoading: e => dispatch(startLoading(e)),
    doneLoading: () => dispatch(doneLoading()),
    setAuth: e => dispatch(setAuthAction(e))
  };
}

class RecommendationsListContainer extends Component {

  constructor(props) {

    super(props);

    this.props.isLoading({
      message: "Loading tasks",
      animate: true
    });

    this.state = {
      taskid: null,
      showOverlay: false,
      showAddOverlay:false,
      total: 0,
      tasks: [],
      recommendations:[],
      type:[],
      rows: [],
      current: 1,
      pages: 1,
      limit: 15,
      filters: {},
    };

    this.handlers = {
      selectPage: this.selectPage.bind(this),
      loadTasks: this.loadTasks.bind(this),
      updateFilter: this.updateFilter.bind(this)
    };
  }

  changeLimit = e => {

    this.props.isLoading();
    this.setState(
      {
        limit: e.value,
        current: 1
      },
      () => this.loadTasks()
    );
  };

  updateFilter = (event, name) => {

    const value = event.target ? event.target.value : event.value;
    this.setState(
      {
        filters: {
          ...this.state.filters,
          [name]: value
        }
      },
      () => {
        this.props.isLoading();
        this.loadTasks(1);
      }
    );
  };

  componentDidMount() {

    api.get("/api/form/filters").then((response) => {
      if (response.data.success) {
        this.setState({ type: response.data.type });
      }
    });
  
    if (this.props.match.params.taskid && this.props.match.params.taskid) {
      this.updateFilter({ value: this.props.match.params.taskid }, "pm");
    } else if (this.props.match.params.agentid) {
      this.updateFilter({ value: this.props.match.params.agentid }, "agent");
    } else if (this.props.match.params.site) {
      this.updateFilter({ value: this.props.match.params.site }, "site");
    } else this.loadTasks();

  }
  submitRecommendationForm = async (e) => { // Use async/await for cleaner handling
    e.preventDefault();

    this.props.isLoading();
    console.log(`constant`,api)
    const formData = new FormData(e.target);
    let data = {};
 
    for (var [key, value] of formData.entries()) {
      data[key] = value;
    }
 

    try {
      const response = await api.post("/api/recommendation/new", data); // Use await
 
      if (response.data.success) {
        this.setState({ showAddOverlay: !this.state.showAddOverlay });
       
        this.props.setOverlay({
          message: "Recommendation ajouter avec succés",
          label: "success",
          button: {
            type: "close",
            label: "OK",
            link: "javascript:void(0)",
          },
       
        });
        
      } else {
        // Handle API errors here
        console.error("API error:", response.data); // Log error details
 
      }
    } catch (err) {
      console.error("Error submitting form:", err); // Log errors
    } finally {
      this.props.isLoading();
    }
  };  
 
  loadTasks(p = this.state.current) {

    let nstate = this.state;
    let that = this;
  
    let crits = buildFilters(this.state.filters); 
    api.get(`/api/getRecommendations?${crits}&page=${p}&limit=${this.state.limit}`).then(function(response) {
   
        if (response.data.success) {
      
        that.setState({
          rows:nstate.rows,
          recommendations:response.data.recommendations,
          total: response.data.total,
          pages: response.data.pages,
        })
        
      } else {
        if (response.data.errcode === 101) {
          that.props.setAuth(false);
        }
      }  
      that.props.doneLoading();
    });
  }

  

  

  toggleOverlay = (e) => {
    e.preventDefault();
    this.setState({ showOverlay: !this.state.showOverlay });
  };
  selectPage = p => {

    if (window.ga) window.ga("send", "event", "TaskList", "selectPage");

    this.props.isLoading();
    this.setState({ current: p });
    this.loadTasks(p);
  };

  exportTMDB = (e) => {

    e.preventDefault();

    let crits = buildFilters(this.state.filters);
    let remote = Constants.apiURL;
    const url = remote + Constants.apiEndPoints.EXPORT_TMDB;

    // window.location.href = url;
    // return;

    e.target.action = url;
    e.target.submit();
  }


  render() {
    
    return (
      <React.Fragment>
        <div className={"container-header"}>
          Recommendations
          {!this.props.session.auth ? <Redirect to="/login" /> : ""}
        </div>

    
        <div className={"row"}>
          <div className={"col-md-2"}>
            <div className={"side-filters"}>
            <React.Fragment>
                  <a
                    href="#"
                    onClick={this.toggleOverlay}
                    className={"btn btn-primary btn-block mb-2"}
                  >
                    <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                    Nouvelle Recommendation
                  </a>
                </React.Fragment>
         
            
            {this.state.showOverlay &&
            <AddNewRecommendationOverlay 
					    open={this.state.showOverlay}

              type={this.state.type}
               reload={()=>this.loadTasks()} 
              close={() =>this.setState({showOverlay:!this.state.showOverlay})}
                
					/>
            }
          
               

              <form
                onSubmit={this.exportResult}
                action="http://localhost/witrack-rollout-backend/public/api/export/csv"
                method="get"
              >
                <input
                  type="hidden"
                  name="token"
                  /* value={this.props.user.token} */
                />
                <button
                  type="submit"
                  className={"btn btn-primary btn-block mb-3"}
                >
                  <FontAwesomeIcon icon={faFileExport} className={"mr-2"} />
                  Exporter le résultat
                </button>
              </form>


              <h4>Recherche</h4>

              <Restricted role={"manager"}>
                <div className={"form-group"}>
                  <label>Type</label>
                   <Select
                    classNamePrefix={"custom-select"}
                    options={[...this.state.type]}  
                    defaultValue={{ label: "All", value: "All" }}
                    
                    onChange={e => this.updateFilter(e, "type")}
                  />  
                  {/* <SelectFilter 
                    label="Site Owner"
                    options={[{ label: "Tous", value: "" }, ...this.props.filters.site_owners]}

                  /> */}
                </div>
              </Restricted>

             <Restricted role={"cluster_manager"}>
                <div className={"form-group"}>
                  <label> Description </label>
                    <input
                    type="text"
                    name="name"
                    onChange={e => this.updateFilter(e, "name")}
                    className={"form-control"}
                    />
                </div>
              </Restricted>   
 
              
  

             
            </div>
          </div>

          <div className={"col-md-10"}>
            <div className={"row mb-3"}>
              <div className={"col-md-6 items-found"}>
                <p>
                  <b>{this.state.total}</b> Recommendations trouvés
                </p>
              </div>
              <div className={"col-md-6 per-page-wrap"}>
                <div className={"per-page-content"}>
                  <p>Résultat par page</p>
                  <Select
                    classNamePrefix={"custom-select"}
                    onChange={this.changeLimit}
                    className={"per-page nm"}
                    options={this.props.filters.result_per_page}
                    value={{ label: this.state.limit, value: this.state.limit }}
                  />
                </div>
                <Pagination data={this.state} handleClick={this.selectPage} />
              </div>
            </div>

            <RecommendationsList
              data={this.state}
              rows={this.state.recommendations}
              handlers={this.handlers}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const TaskListConsumer = props => (
  <SocketContext.Consumer>{socket => <RecommendationsListContainer {...props} socket={socket} />}</SocketContext.Consumer>
);

export default connect(mapStateToProps, mapDispatchToProps)(TaskListConsumer);
