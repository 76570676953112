import React from 'react';
import { NavLink, withRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Restricted from '../../Utils/Restricted';
import { Container } from '../../../Container';

class CommonMenu extends React.Component {


    render() {
        const triggerText = '     Une demandes de visite';
        const onSubmit = (event) => {
            event.preventDefault(event);
            console.log(event.target.type.value);
            console.log(event.target.site.value);
            console.log(event.target.date.value);
            console.log(event.target.ag.value);
            console.log(event.target.p.value);
            console.log(event.target.al.value);
        };
        return (
            <div>
                {/* <div  style={{backgroundColor:"#0000FF"}}> */}
                {/* </div> */}

                {/* <form action="" method="GET"  onSubmit="" >
						<button type="submit" className={"btn btn-primary btn-block mb-2"}> Ajouter une tache </button>
						</form> */}

                {/* <div className="pl-5"> <Container triggerText={triggerText} onSubmit={onSubmit} /></div>   */}

                <div className="row">

                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-1"></div>
                            <div className="col-md-8">
                                <Container triggerText={triggerText} onSubmit={onSubmit} />
                            </div>
                            <div className="col-md-3"></div>
                        </div>

                    </div>

                    <div className="col-md-8">
                        <span className=''>


                            <ul className={"namespace-menu"}>

                             <Restricted scope={"pm"} ><li><NavLink to={"/ssr"}>VDR</NavLink></li></Restricted>
                                
                            <Restricted scope={"pm"} ><li><NavLink to="/sites">Clients</NavLink></li></Restricted>
                            <Restricted scope={"pm"} ><li><NavLink to={"/planning"}>Demandes</NavLink></li></Restricted>
                            
                            <Restricted role={"manager"} >

                                    <li className={"has-children"}>
                                        <a href="#">Utilisateurs</a>
                                        <ul className={"main-submenu"}>

                                            <Restricted role={"manager"} >
                                                <li><NavLink to="/agents">Ingenieurs </NavLink></li>
                                            </Restricted>

                                            <Restricted role={"cluster_manager"} >
                                                <li><NavLink to="/managers"> Managers</NavLink></li>
                                            </Restricted>

                                            <Restricted role={"admin"} >
                                                <li><NavLink to="/clustermanagers">  Cluster Managers </NavLink></li>
                                            </Restricted>

                                        </ul>
                                    </li>
                            </Restricted>

                            <Restricted scope={"pm"} ><li><NavLink to={"/recommendations"}>Recommendations</NavLink></li></Restricted>
                           

                            </ul>


                            <Switch>

                                
 
                              
                            </Switch>




                        </span>
                    </div>
                </div>



            </div>

        );
    }

}

const mapState = state => {
    return {
        session: state.session
    }
}
export default connect(mapState, null, null, { pure: false })(withRouter(CommonMenu));
