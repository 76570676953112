import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PhotoItem from "../../../../Utils/PhotoItem";
import {faCheck,faTrash,faEdit,faSave,faTimes} from "@fortawesome/free-solid-svg-icons";
import api from '../../../../Utils/Api';
const moment = require("moment");

const PAP = ({ data, active,agent,taskId, ...props }) => {
  console.log("data===>", data.PAP?.PreventionOrganisationData?.StructureHse);
  const [checkAdd,setCheckAdd]=useState('false');
  const toggle=()=>{
    setCheckAdd(!checkAdd);
  }
  const onCheckClick = async (e) => {
    e.preventDefault();

   let dataToSend={
    name:"PAP",
    data:data,
    agent:agent,
    taskId:taskId,
   }
    console.log("oncheckclick",e,dataToSend)

    try {
      const response = await api.post("/api/rapport/new", dataToSend); // Use await
 
      if (response.data.success) {
      
        props.setOverlay({
          message: "Rapport mis à jour avec succés",
          label: "success",
          button: {
            type: "close",
            label: "OK",
            link: "javascript:void(0)",
          },
       
        });
        
      } else {
        // Handle API errors here
        console.error("API error:", response.data); // Log error details

        // Optionally display error message to user
      }
    } catch (err) {
      console.error("Error submitting form:", err); // Log errors
    } finally {
     /*  setIsLoading(false);  */ 
    }
  };  

  const url =
    data && data.location
      ? `https://www.google.com/maps/place/${data.location.lat},${data.location.lng}/@${data.location.lat},${data.location.lng},15z`
      : "";

  if (data)
    return (
      <>
        <h4>Prévenetionn et protection</h4>
        <h6>1/ Prévention</h6>
        <h4>Organisation </h4>
        <div>
            <button className="edit-button" onClick={()=>toggle()}>
              <FontAwesomeIcon icon={faEdit} /> Edit
            </button>
            <button className="edit-button" onClick={()=>toggle()}>
              <FontAwesomeIcon icon={faCheck} />
            </button>
          </div>
          
        {data.PreventionOrganisationData && (
          <>

            <div className={"row col-md"}>

              <label>Structure chargée de l'HSE: </label>

              {data.PreventionOrganisationData?.StructureHse}
            </div>
            <div className={"row col-md"}>
              <label>Unité administrative: </label>
              {data.PreventionOrganisationData?.uniteAdminstrative}
            </div>
            <div className={"row col-md"}>
              <label>Qualification : </label>
              {data.PreventionOrganisationData?.qualificationResponsable}
            </div>
            <div className={"row col-md"}>
              <label>Nombre / Fonction: </label>
              {data.PreventionOrganisationData?.nombreFonction}
            </div>
            <div className={"row col-md"}>
              <label>Système de travail: </label>
              {data.PreventionOrganisationData?.SystemeTravail}
            </div>
          </>
        )}

        <h6> Procédure d'urgence </h6>
        {data.PreventionProcedureUrgenceISData &&
        data.PreventionProcedureUrgenceISData.issuesSecours === "Existence" ? (
          <>
            <label>
              Les procédures d’urgence appliquées au niveau du site se résument
              comme suit :
            </label>
            <br />
            <h5>Issues de secours:</h5>
            <div className={"row col-md"}>
              <label>- Nombre: </label>
              {data.PreventionProcedureUrgenceISData.Nombre}
            </div>
            <div className={"row col-md"}>
              <label>- Type de porte: </label>
              {data.PreventionProcedureUrgenceISData.typePorte}
            </div>
            <div className={"row col-md"}>
              <label>- Emplacement: </label>
              {data.PreventionProcedureUrgenceISData.Emplacement}
            </div>
            <div className={"row col-md"}>
              <label>- Accessibilité: </label>
              {data.PreventionProcedureUrgenceISData.Accessibilité}
            </div>
          </>
        ) : (
          <>
            <label>Le site n’est pas doté d’issues de secours.</label>
          </>
        )}
        {data.PreventionProcedureUrgenceESData &&
        data.PreventionProcedureUrgenceESData.EclairageSecours ===
          "Existence" ? (
          <>
            <h5> Éclairage de secours </h5>
            <div className={"row col-md"}>
              <label>- Alimentation:</label>
              {data.PreventionProcedureUrgenceESData.Alimentation}
            </div>
            <div className={"row col-md"}>
              <label>- Répartition: </label>
              {data.PreventionProcedureUrgenceESData.Répartition}
            </div>
            <div className={"row col-md"}>
              <label>- Etat : </label>
              {data.PreventionProcedureUrgenceESData.Etat}
            </div>
          </>
        ) : (
          <>
            <label>Le site n’est pas doté d’éclairage de secours.</label>
          </>
        )}
        {data.PreventionProcedureUrgencePRData &&
        data.PreventionProcedureUrgencePRData.pointRassemblement ===
          "Existence" ? (
          <>
            <h5>Point de rassemblement</h5>

            <div className={"row col-md"}>
              <label>- Affichage : </label>
              {data.PreventionProcedureUrgencePRData.Affichage}
            </div>
            <div className={"row col-md"}>
              <label>- Nombre : </label>
              {data.PreventionProcedureUrgencePRData.Nombre}
            </div>
          </>
        ) : (
          <>
            <label>Le site n’est pas doté d’un point de rassemblement.</label>
          </>
        )}
        {data.PreventionProcedureUrgencePEData &&
        data.PreventionProcedureUrgencePEData.pointEvacuation ===
          "Existence" ? (
          <>
            <h5>Plan d’évacuation</h5>
            <div className={"row col-md"}>
              <label>- Affichage : </label>
              {data.PreventionProcedureUrgencePEData.Affichage}
            </div>
            <div className={"row col-md"}>
              <label>- Visibilité : </label>
              {data.PreventionProcedureUrgencePEData.Visibilité}
            </div>
          </>
        ) : (
          <>
            <label>Le site n’est pas doté d’un plan d’évacuation.</label>
          </>
        )}
        {data.PreventionProcedureUrgencePCData &&
        data.PreventionProcedureUrgencePCData.pointCirculation ===
          "Existence" ? (
          <>
            <h5>Plan de circulation</h5>
            <div className={"row col-md"}>
              <label>- Affichage : </label>
              {data.PreventionProcedureUrgencePCData.Affichage}
            </div>
            <div className={"row col-md"}>
              <label>- Visibilité : </label>
              {data.PreventionProcedureUrgencePCData.Visibilité}
            </div>
            <div className={"row col-md"}>
              <label>- Application : </label>
              {data.PreventionProcedureUrgencePCData.Respect}
            </div>
          </>
        ) : (
          <>
            <label>Le site n’est pas doté d’un plan de circulation.</label>
          </>
        )}
        <h6>Plan d’attaque du feu :</h6>
        <label>
          Plan d’attaque du feu :{" "}
          {data.PreventionProcedureUrgencePAData &&
            data.PreventionProcedureUrgencePAData.planAttaque}
        </label>
        <br />
        {data.PreventionProcedureUrgenceAUData &&
        data.PreventionProcedureUrgenceAUData.appelUrgence === "Existence" ? (
          <>
            <h5>Appel d’urgence</h5>
            <div className={"row col-md"}>
              <label>- Organisme : </label>
              {data.PreventionProcedureUrgenceAUData.Organisme}
            </div>
            <div className={"row col-md"}>
              <label>- Type de ligne: </label>
              {data.PreventionProcedureUrgenceAUData.type}
            </div>
          </>
        ) : (
          <></>
        )}
        <h6>Consignes de sécurité</h6>
        {data.PreventionPlaquesSignaletiquesData &&
       data.PreventionPlaquesSignaletiquesData.plaquesSignaletiques ===
          "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>- Affichage : </label>
              {data.PreventionPlaquesSignaletiquesData.Affichage}
            </div>
            <div className={"row col-md"}>
              <label>- Panneaux de signalisation : </label>
              {data.PreventionPlaquesSignaletiquesData.PanneauxSignalisation}
            </div>
            <div className={"row col-md"}>
              <label>- Application : </label>
              {data.PreventionPlaquesSignaletiquesData.Application}
            </div>
          </>
        ) : (
          <></>
        )}
        <h5>Protection civile</h5>
        {data.PreventionProtectionCivileData && (
          <>
            <div className={"row col-md"}>
              <label>- Durée : </label>
              {data.PreventionProtectionCivileData.Durée}
            </div>
            <div className={"row col-md"}>
              <label>- Distance : </label>
              {data.PreventionProtectionCivileData.Distance}
            </div>
          </>
        )}
        <h6>
          Ligne téléphonique{" "}
          {data.PreventionLigneTelephoniqueData &&
           data.PreventionLigneTelephoniqueData.ligne}
        </h6>
        <h5>Plan d'assistance mutuelle</h5>
        {data.PreventionPlanAssitanceData &&
       data.PreventionPlanAssitanceData.planAssistance === "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>- Avec qui : </label>
              {data.PreventionPlanAssitanceData.AvecQui}
            </div>
          </>
        ) : (
          <></>
        )}
        <h6>Système d'Alarme</h6>
        {data.PreventionAlarmeData &&
        data.PreventionAlarmeData.Alarme == "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>- Nombre : </label>
              {data.PreventionAlarmeData.Nombre}
            </div>
            <div className={"row col-md"}>
              <label>- Portée de l'alarme : </label>
              {data.PreventionAlarmeData.PortééAlarme}
            </div>
            <div className={"row col-md"}>
              <label>- Déclenchement : </label>
              {data.PreventionAlarmeData.Déclenchement}
            </div>
          </>
        ) : (
          <></>
        )}
        <h6>Système de Détection Incendie</h6>
        {data.PreventionSystemeDetectionIncendieData &&
       data.PreventionSystemeDetectionIncendieData.DétectionIncendie ==
          "Existence" ? (
          <>
            <h5>Equipement de contrôle et de signalisation (ECS)</h5>
            <div className={"row col-md"}>
              <label>- Emplacement : </label>
              {data.PreventionEcsData.Emplacement}
            </div>
            <div className={"row col-md"}>
              <label>- Etat : </label>
              {data.PreventionEcsData.Etat}
            </div>
            <div className={"row col-md"}>
              <label>- Fréquence de vérification : </label>
              {data.PreventionEcsData.frequence}
            </div>
            <div className={"row col-md"}>
              <label>- Vérifié par : </label>
              {data.PreventionEcsData.Vérifié}
            </div>

            <h5>Détecteur Automatique d'Incendie (DAI)</h5>
            <div className={"row col-md"}>
              <label>- Type : </label>
              {data.PreventionDaiData?.type}
            </div>
            <div className={"row col-md"}>
              <label>- Nombre : </label>
              {data.PreventionDaiData?.Nombre}
            </div>
            <div className={"row col-md"}>
              <label>- Emplacement : </label>
              {data.PreventionDaiData?.Emplacement}
            </div>
            <div className={"row col-md"}>
              <label>- Fréquence de vérification : </label>
              {data.PreventionDaiData?.frequence}
            </div>
            <div className={"row col-md"}>
              <label>- Vérifié par : </label>
              {data.PreventionDaiData?.Vérifié}
            </div>

            <h5>Déclencheurs Manuels (DM)</h5>
            <div className={"row col-md"}>
              <label>- Etat : </label>
              {data.PreventionDmData?.etat}
            </div>
            <div className={"row col-md"}>
              <label>- Nombre : </label>
              {data.PreventionDmData?.Nombre}
            </div>
            <div className={"row col-md"}>
              <label>- Emplacement : </label>
              {data.PreventionDmData?.Emplacement}
            </div>
            <div className={"row col-md"}>
              <label>- Fréquence de vérification : </label>
              {data.PreventionDmData?.frequence}
            </div>
            <div className={"row col-md"}>
              <label>- Vérifié par : </label>
              {data.PreventionDmData?.Vérifié}
            </div>
          </>
        ) : (
          <></>
        )}
        <h6>Excerice de simulation </h6>
        {data?.PreventionExericeSimulationData &&
        data.PreventionExericeSimulationData.ExcericeSimulation ==
          "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>- Fréquence : </label>
              {data.PreventionExericeSimulationData?.Fréquence}
            </div>
            <div className={"row col-md"}>
              <label>- Collaboration : </label>
              {data.PreventionExericeSimulationData?.Collaboration}
            </div>
          </>
        ) : (
          <></>
        )}
        <h6>Permis de travail/Feu</h6>
        {data?.PreventionPermisDeTravailData &&
        data.PreventionPermisDeTravailData.PermisDeTravail == "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>- Application : </label>
              {data.PreventionPermisDeTravailData.Application}
            </div>
            <div className={"row col-md"}>
              <label>- Travaux : </label>
              {data.PreventionPermisDeTravailData.Travaux}
            </div>
          </>
        ) : (
          <></>
        )}
        <h6>2/ Protection</h6>
        <h5>Extincteur mobiles/fixes</h5>
        {data?.ProtectionExtincteurMobilesFixes &&
        data.ProtectionExtincteurMobilesFixes.existe == "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>Nombre : </label>
              {data.ProtectionExtincteurMobilesFixes.Nombre}
            </div>
            <div className={"row col-md"}>
              <label>Vérifiés par : </label>
              {data.ProtectionExtincteurMobilesFixes.Vérifié}
            </div>
            <div className={"row col-md"}>
              <label>Fréquence de vérification : </label>
              {data.ProtectionExtincteurMobilesFixes.frequence}
            </div>
            <div className={"row col-md"}>
              <label>Accrochage : </label>
              {data.ProtectionExtincteurMobilesFixes.Accrochage}
            </div>
            <div className={"row col-md"}>
              <label>Étiquette : </label>
              {data.ProtectionExtincteurMobilesFixes.Étiquette}
            </div>
            <div className={"row col-md"}>
              <label>Plaques de numérotation extincteurs : </label>
              {
                data.ProtectionExtincteurMobilesFixes
                  .PlaquesNumerotationExtincteurs
              }
            </div>
            <div className={"row col-md"}>
              <label>Commentaire : </label>
              {data.ProtectionExtincteurMobilesFixes.Commentaire}
            </div>
          </>
        ) : (
          <></>
        )}
        <h5>Types d'extincteurs</h5>
        <h5>Eau</h5>
        {data?.ProtectionExtincteurMobilesFixes && (
          <>
            <div className={"row col-md"}>
              <label>Nombre : </label>
              {data.ProtectionExtincteurMobilesFixes.NombreEau}
            </div>
            <div className={"row col-md"}>
              <label>Capacité en Kg : </label>
              {data.ProtectionExtincteurMobilesFixes.capacitéEau}
            </div>
            <div className={"row col-md"}>
              <label>Emplacement : </label>
              {data.ProtectionExtincteurMobilesFixes.EmplacementEau}
            </div>
            <div className={"row col-md"}>
              <label>Commentaire : </label>
              {data.ProtectionExtincteurMobilesFixes.commentaireEau}
            </div>
            <h5>CO2</h5>

            <div className={"row col-md"}>
              <label>Nombre : </label>
              {data.ProtectionExtincteurMobilesFixes.Nombreco2}
            </div>
            <div className={"row col-md"}>
              <label>Capacité en Kg : </label>
              {data.ProtectionExtincteurMobilesFixes.capacitéco2}
            </div>
            <div className={"row col-md"}>
              <label>Emplacement : </label>
              {data.ProtectionExtincteurMobilesFixes.Emplacementco2}
            </div>
            <div className={"row col-md"}>
              <label>Commentaire : </label>
              {data.ProtectionExtincteurMobilesFixes.commentaireco2}
            </div>

            <h5>Mousse</h5>
            <div className={"row col-md"}>
              <label>Nombre : </label>
              {data.ProtectionExtincteurMobilesFixes.Nombremousse}
            </div>
            <div className={"row col-md"}>
              <label>Capacité en Kg : </label>
              {data.ProtectionExtincteurMobilesFixes.capacitémousse}
            </div>
            <div className={"row col-md"}>
              <label>Emplacement : </label>
              {data.ProtectionExtincteurMobilesFixes.Emplacementmousse}
            </div>
            <div className={"row col-md"}>
              <label>Commentaire : </label>
              {data.ProtectionExtincteurMobilesFixes.commentairemousse}
            </div>

            <h5>Poudre</h5>
            <div className={"row col-md"}>
              <label>Nombre : </label>
              {data.ProtectionExtincteurMobilesFixes.Nombrepoudre}
            </div>
            <div className={"row col-md"}>
              <label>Capacité en Kg : </label>
              {data.ProtectionExtincteurMobilesFixes.capacitépoudre}
            </div>
            <div className={"row col-md"}>
              <label>Emplacement : </label>
              {data.ProtectionExtincteurMobilesFixes.Emplacementpoudre}
            </div>
            <div className={"row col-md"}>
              <label>Commentaire : </label>
              {data.ProtectionExtincteurMobilesFixes.commentairepoudre}
            </div>
          </>
        )}
        <h5>Robinet incendie armé</h5>
        {data?.ProtectionRobinetIncendieArmé &&
        data.ProtectionRobinetIncendieArmé.existe == "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>Présiser le nombre : </label>
              {data.ProtectionRobinetIncendieArmé.Nombre}
            </div>
            <div className={"row col-md"}>
              <label>Source d'alimentation : </label>
              {data.ProtectionRobinetIncendieArmé.sourceAlimentationIncendie}
            </div>

            <div className={"row col-md"}>
              <label>Emplacement : </label>
              {data.ProtectionRobinetIncendieArmé.Emplacement}
            </div>
            <div className={"row col-md"}>
              <label>Etat d'entretien : </label>
              {data.ProtectionRobinetIncendieArmé.Etat}
            </div>
            <div className={"row col-md"}>
              <label>Préciser la longeur du jet : </label>
              {data.ProtectionRobinetIncendieArmé.Commentaire}
            </div>
            <div className={"row col-md"}>
              <label>Canalisation d'eau : </label>
              {data.ProtectionRobinetIncendieArmé.canalisationEau}
            </div>
            <div className={"row col-md"}>
              <label>Fréquence de vérification : </label>
              {data.ProtectionRobinetIncendieArmé.frequence}
            </div>
            <div className={"row col-md"}>
              <label>Vérifiés par : </label>
              {data.ProtectionRobinetIncendieArmé.Vérifié}
            </div>
          </>
        ) : (
          <></>
        )}
        <h5>Poteau d'incendie </h5>
        {data?.ProtectionPoteauIncendieData &&
        data.ProtectionPoteauIncendieData.existe == "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>Nombre : </label>
              {data.ProtectionPoteauIncendieData.Nombre}
            </div>

            <div className={"row col-md"}>
              <label>Etat d'entretien : </label>
              {data.ProtectionPoteauIncendieData.Etat}
            </div>
            <div className={"row col-md"}>
              <label>Emplacement : </label>
              {data.ProtectionPoteauIncendieData.Emplacement}
            </div>
            <div className={"row col-md"}>
              <label>Fréquence de vérification : </label>
              {data.ProtectionPoteauIncendieData.frequence}
            </div>
            <div className={"row col-md"}>
              <label>Vérifie par : </label>
              {data.ProtectionPoteauIncendieData.Vérifié}
            </div>
            <div className={"row col-md"}>
              <label>Commentaire : </label>
              {data.ProtectionPoteauIncendieData.Commentaire}
            </div>
          </>
        ) : (
          <></>
        )}
        <h5>STATION DE POMPAGE </h5>
        {data?.ProtectionSPData &&
        data.ProtectionSPData.StationPompage === "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>Source d'alimentation : </label>
              {data.ProtectionSPData.sourceAlimentation}
            </div>
            <div className={"row col-md"}>
              <label>Fréquence de vérification : </label>
              {data.ProtectionSPData.sourceAlimentation}
            </div>
            <div className={"row col-md"}>
              <label>Vérifiés par : </label>
              {data.ProtectionSPData.Vérifié}
            </div>
            <label>Pompe électrique </label>
            <div className={"row col-md"}>
              <label>- Nombre :</label>
              {data.ProtectionPompeElectriqueData.Nombre}
            </div>
            <div className={"row col-md"}>
              <label>- Puissance :</label>
              {data.ProtectionPompeElectriqueData.Puissance}
            </div>
            <div className={"row col-md"}>
              <label>- Etat :</label>
              {data.ProtectionPompeElectriqueData.Etat}
            </div>

            <label>Pompe diesel </label>
            <div className={"row col-md"}>
              <label>- Nombre :</label>
              {data.ProtectionPompedieselData.Nombre}
            </div>
            <div className={"row col-md"}>
              <label>- Puissance :</label>
              {data.ProtectionPompedieselData.Puissance}
            </div>
            <div className={"row col-md"}>
              <label>- Etat :</label>
              {data.ProtectionPompedieselData.Etat}
            </div>

            <label>Pompe Jockey </label>
            <div className={"row col-md"}>
              <label>- Nombre :</label>
              {data.ProtectionPompeJockeyData.Nombre}
            </div>
            <div className={"row col-md"}>
              <label>- Puissance :</label>
              {data.ProtectionPompeJockeyData.Puissance}
            </div>
            <div className={"row col-md"}>
              <label>- Etat :</label>
              {data.ProtectionPompeJockeyData.Etat}
            </div>
          </>
        ) : (
          <></>
        )}
        <h5>Extracteur d'air</h5>
        {data?.ProtectionEAData &&
        data.ProtectionEAData.ExtracteurAir == "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>Type : </label>
              {data.ProtectionEAData.type}
            </div>
            <div className={"row col-md"}>
              <label>État : </label>
              {data.ProtectionEAData.Etat}
            </div>
            <div className={"row col-md"}>
              <label>Emplacement : </label>
              {data.ProtectionEAData.Emplacement}
            </div>
            <div className={"row col-md"}>
              <label>Fréquence de vérification : </label>
              {data.ProtectionEAData.frequence}
            </div>
            <div className={"row col-md"}>
              <label>Vérifiés par : </label>
              {data.ProtectionEAData.Vérifié}
            </div>
            <div className={"row col-md"}>
              <label>Commentaire : </label>
              {data.ProtectionEAData.Commentaire}
            </div>
          </>
        ) : (
          <></>
        )}
        <h5>Système d'extinction automatique</h5>
        {data?.ProtectionSEAData &&
        data.ProtectionSEAData.SystemExtinctionAutomatique == "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>Type : </label>
              {data.ProtectionSEAData.type}
            </div>
            <div className={"row col-md"}>
              <label>État : </label>
              {data.ProtectionSEAData.Etat}
            </div>
            <div className={"row col-md"}>
              <label>Emplacement : </label>
              {data.ProtectionSEAData.Emplacement}
            </div>
            <div className={"row col-md"}>
              <label>Fréquence de vérification : </label>
              {data.ProtectionSEAData.frequence}
            </div>
            <div className={"row col-md"}>
              <label>Vérifiés par : </label>
              {data.ProtectionSEAData.Vérifié}
            </div>
            <div className={"row col-md"}>
              <label>Commentaire : </label>
              {data.ProtectionSEAData.Commentaire}
            </div>
          </>
        ) : (
          <></>
        )}
        <h5>Système de désenfumage</h5>
        {data?.ProtectionSystemDesenfumageData &&
        data.ProtectionSystemDesenfumageData.systemDesenfumage ==
          "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>Type : </label>
              {data.ProtectionSystemDesenfumageData.type}
            </div>
            <div className={"row col-md"}>
              <label>État : </label>
              {data.ProtectionSystemDesenfumageData.Etat}
            </div>
            <div className={"row col-md"}>
              <label>Emplacement : </label>
              {data.ProtectionSystemDesenfumageData.Emplacement}
            </div>
            <div className={"row col-md"}>
              <label>Fréquence de vérification : </label>
              {data.ProtectionSystemDesenfumageData.frequence}
            </div>
            <div className={"row col-md"}>
              <label>Vérifiés par : </label>
              {data.ProtectionSystemDesenfumageData.Vérifié}
            </div>
            <div className={"row col-md"}>
              <label>Commentaire : </label>
              {data.ProtectionSystemDesenfumageData.Commentaire}
            </div>
          </>
        ) : (
          <></>
        )}
        <h5>Bacs à sable</h5>
        {data?.ProtectionBacsSableData &&
        data.ProtectionBacsSableData.BacsSable == "Existence" ? (
          <>
            <div className={"row col-md"}>
              <label>État : </label>
              {data.ProtectionBacsSableData.Etat}
            </div>
            <div className={"row col-md"}>
              <label>Emplacement : </label>
              {data.ProtectionBacsSableData.Emplacement}
            </div>
            <div className={"row col-md"}>
              <label>Fréquence de vérification : </label>
              {data.ProtectionBacsSableData.frequence}
            </div>
            <div className={"row col-md"}>
              <label>Vérifiés par : </label>
              {data.ProtectionBacsSableData.Vérifié}
            </div>
            <div className={"row col-md"}>
              <label>Commentaire : </label>
              {data.ProtectionBacsSableData.Commentaire}
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  else return <>Didn't clock in</>;
};

export default PAP;
