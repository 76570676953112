import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";
import {faCheck,faTrash,faEdit,faSave,faTimes} from "@fortawesome/free-solid-svg-icons";
import api from '../../../../Utils/Api';
 
import ApiService from '../../../../Utils/ApiService';

const moment = require("moment");

const Stockage  = ({ data, active,agent,taskId, ...props }) => {
  console.log("data construction=====>",data)
  const [checkAdd,setCheckAdd]=useState('false');
 
  const toggle=()=>{
    setCheckAdd(!checkAdd);
  }
  const onCheckClick = async (e) => {
    e.preventDefault();

   let dataToSend={
    name:"stockage",
    data:data,
    agent:agent,
    taskId:taskId,
   }
    console.log("oncheckclick",e,dataToSend)

    try {
      const response = await api.post("/api/rapport/new", dataToSend); // Use await
 
      if (response.data.success) {
      
        props.setOverlay({
          message: "Rapport mis à jour avec succés",
          label: "success",
          button: {
            type: "close",
            label: "OK",
            link: "javascript:void(0)",
          },
       
        });
        
      } else {
        // Handle API errors here
        console.error("API error:", response.data); // Log error details

        // Optionally display error message to user
      }
    } catch (err) {
      console.error("Error submitting form:", err); // Log errors
    } finally {
     /*  setIsLoading(false);  */ 
    }
  };   
  const url =
    data && data.location
      ? `https://www.google.com/maps/place/${data.location.lat},${data.location.lng}/@${data.location.lat},${data.location.lng},15z`
      : "";

  if (data)
    return (
      <>
     
            <h6>Stockage (Fiche Technique)</h6>
            
            <div>
            <button className="edit-button" onClick={()=>toggle()}>
              <FontAwesomeIcon icon={faEdit} /> Edit
            </button>
            <button className="edit-button" onClick={()=>toggle()}>
              <FontAwesomeIcon icon={faCheck} />
            </button>
          </div>

            <br />

      <div className={checkAdd ? "ui-overlay " : "ui-overlay open"}>
        <div className={"ui-overlay-content delete-overlay col-md-4 offset-md-4"}>
          <button onClick={e => { toggle(); } } className={"ui-overlay-close btn btn-danger btn-sm"}>
          <FontAwesomeIcon icon={faTimes} />
          </button>
  
          Voulez-vous vraiment ajouter cette partie au rapport ?<br />
          Cette action est irreversible
          <div className={"delete-buttons"} onClick={e => { toggle(); } } style={{
            display:"flex",justifyContent:"space-evenly"}}>

          <button onClick={e => { toggle(); } } style={{paddingRight:"1rem"}} className={"btn btn-danger ml-2"}>No</button>
          <button onClick={(e)=>onCheckClick(e)}   style={{paddingRight:"1rem"}} className={"btn btn-info"}>Oui</button>
          </div>
        </div>
      </div>
            {data?.stockageItemStock && (
              <>
            <div className={"row col-md"}>
              <label>Moyens de manutention : </label>
              {data.stockageItemStock.manutention}
            </div>
            <div className={"row col-md"}>
              <label>Gestion des stocks: </label>
              {data.stockageItemStock.mode_gestion}
            </div>
          </>
            )}
        
      
        {data?.stockageList && (
          <>
            <h6>Stockage List(Fiche Technique)</h6>
            <br />
            {data.stockageList.map((item) => (
              <>
                <h6>Item numéro {item.id} </h6>
                <div className={"row col-md"}>
                  <label>Type : </label>
                  {item?.type}
                </div>
                <div className="row col-md">
                  <label>Désignation: </label>
                  {item?.Désignation}
                </div>

                <div className="row col-md">
                  <label>Batiments: </label>
                  {item?.batiments}
                </div>
                <div className={"row col-md"}>
                  <label>Nature : </label>
                  {item?.nature}
                </div>
                <div className="row col-md">
                  <label>Mode de stockage: </label>
                  {item?.mode_stockage}
                </div>
              </>
            ))}
          </>
        )}
      </>
    );
  else return <>Didn't clock in</>;
};

export default Stockage;
