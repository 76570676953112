import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";
import {faCheck,faTrash,faEdit,faSave,faTimes} from "@fortawesome/free-solid-svg-icons";
import api from '../../../../Utils/Api';
 
import ApiService from '../../../../Utils/ApiService';

const moment = require("moment");

const PAE = ({ data, active,agent,taskId, ...props }) => {
   console.log("PAE====>",Object.keys(data))
  const [checkAdd,setCheckAdd]=useState('false');
 
  const toggle=()=>{
    setCheckAdd(!checkAdd);
  }
  const onCheckClick = async (e) => {
    e.preventDefault();

   let dataToSend={
    name:"PAE",
    data:data,
    agent:agent,
    taskId:taskId,
   }
    console.log("oncheckclick",e,dataToSend)

    try {
      const response = await api.post("/api/rapport/new", dataToSend); // Use await
 
      if (response.data.success) {
      
        props.setOverlay({
          message: "Rapport mis à jour avec succés",
          label: "success",
          button: {
            type: "close",
            label: "OK",
            link: "javascript:void(0)",
          },
       
        });
        
      } else {
        // Handle API errors here
        console.error("API error:", response.data); // Log error details

        // Optionally display error message to user
      }
    } catch (err) {
      console.error("Error submitting form:", err); // Log errors
    } finally {
     /*  setIsLoading(false);  */ 
    }
  };  
 
  const url =
    data && data.location
      ? `https://www.google.com/maps/place/${data.location.lat},${data.location.lng}/@${data.location.lat},${data.location.lng},15z`
      : "";

  if (data)
    return (
      <>
        
         <h6>PROCESS ET EQUIPEMTS</h6>
         <div>
            <button className="edit-button" onClick={()=>toggle()}>
              <FontAwesomeIcon icon={faEdit} /> Edit
            </button>
            <button className="edit-button" onClick={()=>toggle()}>
              <FontAwesomeIcon icon={faCheck} />
            </button>
          </div>
        <br />
        <div className={checkAdd ? "ui-overlay " : "ui-overlay open"}>
        <div className={"ui-overlay-content delete-overlay col-md-4 offset-md-4"}>
          <button onClick={e => { toggle(); } } className={"ui-overlay-close btn btn-danger btn-sm"}>
          <FontAwesomeIcon icon={faTimes} />
          </button>
  
          Voulez-vous vraiment ajouter cette partie au rapport ?<br />
          Cette action est irreversible
          <div className={"delete-buttons"} onClick={e => { toggle(); } } style={{
            display:"flex",justifyContent:"space-evenly"}}>

          <button onClick={e => { toggle(); } } style={{paddingRight:"1rem"}} className={"btn btn-danger ml-2"}>No</button>
          <button onClick={(e)=>onCheckClick(e)}   style={{paddingRight:"1rem"}} className={"btn btn-info"}>Oui</button>
          </div>
        </div>
      </div>
        <h6> CAPACITE DE PRODUCTION </h6>
      
        <div className={"row col-md-6"}>
        <label>Capacité journaliére : </label>
        {data.capaciteJournaliere}
      </div>
      <div className={"row col-md-6"}>
        <label>Capacité annuelle : </label>
        {data.capaciteAnnuelle}
      </div>
      <h6>ACTIVITÉS EXERCÉES </h6>
        <div className={"row col-md-6"}>
            <label>Activité : </label>
            {data.acivite}
        </div>
        <div className={"row col-md-6"}>
        <label>Durée d'activité : </label>
            {data.dureeActivite}
         </div>
         <div className={"row col-md-6"}>
        <label>Saison du Pic de production : </label>
            {data.saisonProduction}
         </div>
         <div className={"row col-md-6"}>
            <label>Période de mise à la vente  : </label>
            {data.periodeMiseVente}
         </div>
         <div className={"row col-md-6"}>
            <label>Produits finis  : </label>
            {data.produitsFinis}
         </div>
         <div className={"row col-md-6"}>
            <label> Projets d’extension  : </label>
            {data.projetExtension}
         </div>
         <h6>   MATIERES PREMIERES </h6>
         <div className={"row col-md-6"}>
            <label> Matière  : </label>
            {data.Matière}
         </div>
         <div className={"row col-md-6"}>
            <label> Nature  : </label>
            {data.Nature}
         </div>
         <div className={"row col-md-6"}>
            <label> Forme  : </label>
            {data.Forme}
         </div>
         <div className={"row col-md-6"}>
            <label> Fournisseur (Pays)  : </label>
            {data.Fournisseur}
         </div>
        <h6>PROCESSUS DE PRODUCTION </h6>
        <div className={"row col-md-6"}>
            <label> Nombre de ligne de production : </label>
            {data.ligneProduction}
         </div>
         <h6>CONTROLE DE QUALITE </h6>
         <div className={"row col-md-6"}>
            <label> Laboratoire : </label>
            {data.laboratoire}
         </div>
         <div className={"row col-md-6"}>
            <label> Les différents contrôles  : </label>
            {data.controleQualite}
         </div>
         <div className={"row col-md-6"}>
            <label> Prélévement : </label>
            {data.Prélévement}
         </div>


        <h6>PRINCIPAUX EQUIPEMENTS </h6>
        <div className={"row col-md-6"}>
            <label> Equipements : </label>
            {data.Equipements}
         </div>
         <div className={"row col-md-6"}>
            <label> Marques : </label>
            {data.Marques}
         </div>
         <div className={"row col-md-6"}>
            <label> N° de série : </label>
            {data.numeroSerie}
         </div>
         <div className={"row col-md-6"}>
            <label> Année d’acquisition : </label>
            {data.anneeAcquisition}
         </div>
         <div className={"row col-md-6"}>
            <label> Valeurs d’acquisition DA : </label>
            {data.valeurAcquisition}
         </div>


      </>
    );
  else return <>Didn't clock in</>;
};

export default PAE;
