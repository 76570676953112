import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash , faEdit} from '@fortawesome/free-solid-svg-icons';
import api from '../../../Utils/Api';
import Constants from '../../../Utils/Constants';
import ApiService from '../../../Utils/ApiService';
import { useSelector } from 'react-redux';
import 'leaflet/dist/leaflet.css' 
import '@geoman-io/leaflet-geoman-free';  
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'; 
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import EditRecommendationOverlay from "./EditRecommendationOverlay";


const RecommendationsRow = ({ recommendation, key,  deleteTask,types,reload, ...props }) => {
  console.log("Recommendations Row===>",types)
const { session } = useSelector(state => ({ session: state.session }));
  const [showOverlayRecommendation,setShowOverlayRecommendation]=useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showDeleteOverlay,setShowDeleteOverlay]=useState(false);
  const [showDeleteOverlayConfirmation,setShowDeleteOverlayConfirmation]=useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const exportForm = useRef(null);
  const [selected_recommendation,setSelectedRecommendation]=useState(0)
  const detailInput = useRef(null); 
  const [deleteSteps,setDeleteSteps]=useState(0);
  useEffect(() => { 
    if (props.isLoading) {
      setIsLoading(props.isLoading);  
    }
  }, [props.isLoading]);  
  const selectTask = (show = true) => {

    if (!showDetail) {
      setIsLoading(true); 
      ApiService.fetchVdrTaskDetail(recommendation.task_id)
        .then(res => {
          if (res.data.success && res.data.total > 0) {
            setDataLoaded(true);
            setData(res.data.tasks[0]);
          }
          setIsLoading(false); 
        })
    }
    setShowDetail(!showDetail)
  }

 const toggleDeleteOverlay=()=>{
  setShowDeleteOverlay(!showDeleteOverlay);
 }
 
 const deleteRecommendation = (recommendation) => {

    setShowDeleteOverlay(true);
    setDeleteSteps(1);
    setSelectedRecommendation(recommendation.id)
   
}

const nextDeleteStep = () => {
    setShowDeleteOverlay(false);
    setShowDeleteOverlayConfirmation(true);
    setDeleteSteps(2);
  
}

const confirmDelete = () => {

  setIsLoading(true); 
  setShowDeleteOverlayConfirmation(false);
  console.log("selected recommendation===>",selected_recommendation)
 const data={
  id:selected_recommendation
 }
  api.post('/api/recommendation/delete',data)
    .then(() => {
      reload();
      setIsLoading(false); 
    })
}

 
  
  return (
   <>
      <form method="post" ref={exportForm} target={"_blank"} >
        <input type="hidden" name={"pm"} ref={detailInput} />
        <input type="hidden" name={"token"} value={session.user.token} />
      </form>
      <div className={"row "}>
        <div className={"col-md-12"}>
          <div className={"row ctable-row"}>
            <div className={"col-md-1 table-space-between"}>
              <span>{recommendation.id}</span>
            </div>

            <div className={"col-md-2"}>
              <a className={"site-link"} >
             {recommendation.type}
              </a>
            </div>

            <div className={"col-md-7"}>
       
                {recommendation.name}
              
            </div>

           

           

           

            <div className={"col-md-2 task-details-col"}>
             
 
                <button onClick={() => deleteRecommendation(recommendation)} className="task-action-btn" >
                <FontAwesomeIcon className={"dialog-icon danger"} icon={faTrash} />
                Supprimer</button>
                <button onClick={() => setShowOverlayRecommendation(!showOverlayRecommendation)} className="task-action-btn" >
                <FontAwesomeIcon className={"dialog-icon danger"} icon={faEdit} />
                Modifier</button>

               
            </div>

          </div>
          <EditRecommendationOverlay 
					    open={showOverlayRecommendation}
              recommendation={recommendation} 
              type={types}
              reload={reload}
              close={() =>
                setShowOverlayRecommendation(!showOverlayRecommendation)
                /*  selected_site: {} */
           
              }
					 
					/>


				<div className={showDeleteOverlay ? "ui-overlay delete-task open" : "ui-overlay delete-task"}>
					<div className={"ui-overlay-content status-overlay-text col-md-4 offset-md-4"}>
						<button
							onClick={() => setShowDeleteOverlay(false)}
							className={"ui-overlay-close btn btn-danger btn-sm"}>
							<FontAwesomeIcon icon={faTimes} />
						</button>
						<h5 className={"mb-4"}>Voulez-vous vraiment supprimer cette Recommendation ?</h5>
						<button type="submit" className={"btn btn-success btn-sm mt-2"}>ANNULER</button>
						<button onClick={nextDeleteStep} type="submit" className={"btn btn-danger btn-sm ml-2 mt-2"}>OUI, SUPPRIMER</button>
					</div>
				</div>

				<div className={showDeleteOverlayConfirmation ? "ui-overlay delete-task open" : "ui-overlay delete-task"}>
					<div className={"ui-overlay-content status-overlay-text col-md-4 offset-md-4"}>
						<button
							onClick={() => setShowDeleteOverlayConfirmation(false)}
							className={"ui-overlay-close btn btn-danger btn-sm"}>
							<FontAwesomeIcon icon={faTimes} />
						</button>
						<h5 className={"mb-5"}>Êtes-vous sur de vouloir supprimer cette Recommendation ?</h5>
						<button onClick={() => setShowDeleteOverlayConfirmation(false)} type="submit" className={"btn btn-success btn-sm mt-2"}>Retour</button>
						<button onClick={confirmDelete} type="submit" className={"btn btn-danger btn-sm mt-2 ml-2"}>Confirmer la suppression</button>
						<div>
							<span className={"font-exp"}>Attention, cette action est irreversible.</span>
						</div>
					</div>
				</div>
          
        </div>
      </div>
    </>
  )
}

export default RecommendationsRow;
