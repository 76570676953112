import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";
import {faCheck,faTrash,faEdit,faSave,faTimes} from "@fortawesome/free-solid-svg-icons";
import api from '../../../../Utils/Api';
 
import ApiService from '../../../../Utils/ApiService';

const moment = require("moment");

const Situation = ({ data, active,agent,taskId, ...props }) => {
  const [checkAdd,setCheckAdd]=useState('false');
 
  const toggle=()=>{
    setCheckAdd(!checkAdd);
  }
  const onCheckClick = async (e) => {
    e.preventDefault();

   let dataToSend={
    name:"situation",
    data:data,
    agent:agent,
    taskId:taskId,
   }
    console.log("oncheckclick",e,dataToSend)

    try {
      const response = await api.post("/api/rapport/new", dataToSend); // Use await
 
      if (response.data.success) {
      
        props.setOverlay({
          message: "Rapport mis à jour avec succés",
          label: "success",
          button: {
            type: "close",
            label: "OK",
            link: "javascript:void(0)",
          },
       
        });
        
      } else {
        // Handle API errors here
        console.error("API error:", response.data); // Log error details

        // Optionally display error message to user
      }
    } catch (err) {
      console.error("Error submitting form:", err); // Log errors
    } finally {
     /*  setIsLoading(false);  */ 
    }
  };  
 
  const url =
    data && data.location
      ? `https://www.google.com/maps/place/${data.location.lat},${data.location.lng}/@${data.location.lat},${data.location.lng},15z`
      : "";

  if (data)
    return (
      <>
        
         <h6>Situation</h6>
         <div>
            <button className="edit-button" onClick={()=>toggle()}>
              <FontAwesomeIcon icon={faEdit} /> Edit
            </button>
            <button className="edit-button" onClick={()=>toggle()}>
              <FontAwesomeIcon icon={faCheck} />
            </button>
          </div>
        <br />
        <div className={checkAdd ? "ui-overlay " : "ui-overlay open"}>
        <div className={"ui-overlay-content delete-overlay col-md-4 offset-md-4"}>
          <button onClick={e => { toggle(); } } className={"ui-overlay-close btn btn-danger btn-sm"}>
          <FontAwesomeIcon icon={faTimes} />
          </button>
  
          Voulez-vous vraiment ajouter cette partie au rapport ?<br />
          Cette action est irreversible
          <div className={"delete-buttons"} onClick={e => { toggle(); } } style={{
            display:"flex",justifyContent:"space-evenly"}}>

          <button onClick={e => { toggle(); } } style={{paddingRight:"1rem"}} className={"btn btn-danger ml-2"}>No</button>
          <button onClick={(e)=>onCheckClick(e)}   style={{paddingRight:"1rem"}} className={"btn btn-info"}>Oui</button>
          </div>
        </div>
      </div>
        <h6> Situation DU RISQUE </h6>
       
      <div className={"row col-md-6"}>
        <label>Localisation  :  </label>
        {data.localisation}
      </div>

 
      <div className={"row col-md-6"}>
        <label>Adresse  :  </label>
        {data.Adresse}
      </div>
      <div className={"row col-md-6"}>
        <label>Commune:  </label>
        {data.Commune}
      </div> 
      <div className={"row col-md-6"}>
        <label>Wilaya :  </label>
        {data.wilaya}
      </div>
      <div className={"row col-md-6"}>
        <label>Proximité au zone urbaine:  </label>
        {data.zoneUrbaine}
      </div> 
      <h6>Coordonnées géographiques</h6>
      <div className={"row col-md-6"}>
        <label>Latitude :  </label>
        {data.latitude}
      </div> 
      <div className={"row col-md-6"}>
        <label>Altitude(m) : </label>
        {data.altitude}
      </div>
      <div className={"row col-md-6"}>
        <label>Longitude : </label>
        {data.longitude}
      </div>
      <h6>Voisinage</h6>
      <div className={"row col-md-6"}>
        <label>A l'Est : </label>
        {data.est}
      </div>
      <div className={"row col-md-6"}>
        <label>A l'Ouest : </label>
        {data.ouest}
      </div>
      <div className={"row col-md-6"}>
        <label>Au Nord : </label>
        {data.nord}
      </div>
      <div className={"row col-md-6"}>
        <label>Au Sud : </label>
        {data.sud}
      </div>
      
      <div className={"row col-md-6"}>
        <label>Au Nord-Est : </label>
        {data.nordEst}
      </div>
      <div className={"row col-md-6"}>
        <label>Au Nord-Ouest: </label>
        {data.nordOuest}
      </div>
      <div className={"row col-md-6"}>
        <label>Au Sud-Est : </label>
        {data.SudEst}
      </div>
      <div className={"row col-md-6"}>
        <label>Au Sud-Ouest : </label>
        {data.SudOuest}
      </div>
      <h6>Autres voisinage - Lignes électriques</h6>
       
      {data.exsistelignesElectrique=="Existence" &&
      <>
      <div className={"row col-md-6"}>
        <label> Lignes électriques: </label>
        {data.lignesElectrique}
      </div>
      </>
    }
    {data.existeconduitesSouterraines &&
    
       
      <div className={"row col-md-6"}>
        <label>Conduites souterraines  : </label>
        {data.conduitesSouterraines}
      </div>
    
    }
    {data.exsistelOueds && 
     
    
      <div className={"row col-md-6"}>
        <label>Oueds: </label>
        {data.oueds}
      </div>
    
    }  
    <h6>Tiers à haut risque</h6>

   {data.exsisteHautRisque && 
   <>
     
      <div className={"row col-md-6"}>
        <label>Activité : </label>
        {data.activite}
      </div>

      <div className={"row col-md-6"}>
        <label>Distance : </label>
        {data.distanceTiers}
      </div>
      </>
    }
    <h6>CLIMAT</h6>
     <div className={"row col-md-6"}>
        <label>wilaya : </label>
        {data.wilayaclima}
      </div>
      <div className={"row col-md-6"}>
        <label>Classification : </label>
        {data.classification}
      </div>

      <div className={"row col-md-6"}>
        <label> Précipitation Moyenne Annuelle (mm): </label>
        {data.precipitation}
      </div>
      <div className={"row col-md-6"}>
        <label>La vitesse des vents : (Direction des vents) : </label>
        {data.vitesseVents}
      </div>
      <div className={"row col-md-6"}>
        <label>Température minimale (..°C)  : </label>
        {data.temperatureminimale}
      </div>
      <div className={"row col-md-6"}>
        <label>Température maximale (..°C)  : </label>
        {data.temperaturemaximale}
      </div>
      


      </>
    );
  else return <>Didn't clock in</>;
};

export default Situation;
