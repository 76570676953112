import React, { useState } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import api from "../../Utils/Api";
import * as helpers from "../../Utils/Helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { formToObject } from "../../Utils/Helpers";
import { useDispatch } from "react-redux";
import {
  startLoading,
  setFlash,
  doneLoading,
  setOverlay,
} from "../../Redux/actions";
import { Trans } from "react-i18next";
import { Multiselect } from "multiselect-react-dropdown";
import WindowedSelect from "react-windowed-select";
import { components, createFilter } from "react-windowed-select";
import { Redirect } from "react-router-dom";
import HomeView from "../Home/HomeView";
import { withRouter } from "react-router";
import { Route } from "react-router-dom";
import DeliveryRouter from "../../Routers/DeliveryRouter";
import SSRRouter from "../../Routers/SSRRouter";
import FlashMessage from "react-flash-message";
import { get } from "jquery";
import { flashMessage } from "../../Redux/reducers/UI";
import Success from "./sucsess";
import Failure from "./failur";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import Filters from "../../Redux/reducers/Filters";

const moment = require("moment");

const type_demande = [
  { label: "Retour Sur Site", value: "Retour Sur Site" },
  //{ label: "Nouveau Client", value: "Nouveau Client" },

   { label: "Démarchage", value: "Démarchage" },
   { label: "appel d'offre", value: "appel d'offre" },
   { label: "soumission", value: "soumission" },
];
const mapStateToProps = (state) => {
  return {
    // auth: state.session.auth,
    user: state.session.user,
    filters: state.filters,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isLoading: (e) => dispatch(startLoading(e)),
    doneLoading: () => dispatch(doneLoading()),
    flash: (d) => dispatch(setFlash(d)),
    setOverlay: (e) => dispatch(setOverlay(e)),
  };
};

const customFilter = createFilter({ ignoreAccents: false });

const customComponents = {
  ClearIndicator: (props) => (
    <components.ClearIndicator {...props}>clear</components.ClearIndicator>
  ),
};

export class Form extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sites: [],

      planning_date: moment(new Date()).format("YYYY-MM-DD"),

      showMessage: false,
      showMessage2: false,
      siteName: { label: "", value: "" },
      isLoaded: false,

      filters: {},
      agents: [],
      selectedAgents: [],
      alert_message: "",
      types: [],
      types_value: "",
      selected_type: "ssr",
      files: [],
      file_id: "",
      messageError: null,
    };
    let that = this;
    console.log("filter " + Filters.type_demande);

    this.formSubmit = this.formSubmit.bind(this);
    this.handleChangeSelected = this.handleChangeSelected.bind(this);
    this.handleChangeSite = this.handleChangeSite.bind(this);
    this.handleChangeSector = this.handleChangeSector.bind(this);

    this.setAgent = this.setAgent.bind(this);
    this.setTeam = this.setTeam.bind(this);
    this.changeCMCode = this.changeCMCode.bind(this);
    this.sitesArray = [];
  }

  changeSub(e) {
    this.setState({
      info: {
        ...this.state.info,
        subscribe: e.value,
      },
    });
  }
  handleChangeSelected(event) {
    this.setState({ selected_type: event.target.value });
  }
  formSubmit = (e) => {
    e.preventDefault();
    this.setState({ showMessage: false });
    this.setState({ showMessage2: false });

    const formdata = new FormData(e.target);

    let data = {};

    for (var [key, value] of formdata.entries()) data[key] = value;

    data["agents"] = this.state.selectedAgents;

    let that = this;
    // const data = helpers.formToObject(e.target);

    data["task_type"] = "ssr";
    api.post("/api/planning/assign", data).then((response) => {
      if (response.data.success) {
        this.setState({ alert_message: "success" });
      } else {
        this.setState({
          alert_message: "Error",
          messageError: response.data.error,
        });
      }
    });
  };

  onSelectSite(selectedList, selectedItem) {
    this.sitesArray.push(selectedItem);
  }
  handleChangeSite(e) {
    // let cmImpactedSite = this.state.cmImpactedSite
    this.setState({ sites: e.target.sites }, () => {
      console.log("hello handle change", this.state.sites);
    });
  }
  handleChangeSector(e) {
    let cmImpactedSector = this.state.cmImpactedSector;
    this.setState({ cmImpactedSector: !cmImpactedSector });
  }

  setAgent(e) {
    var sitePMAgentOwner = { label: e.label, value: e.value };
    this.setState({ sitePMAgentOwner: sitePMAgentOwner });
  }
  setSelectedAgents = (selectedOptions) => {
    console.log("selected ption", selectedOptions, this.state.selectedAgents);
    const selectedAgentsArray = selectedOptions.map((option) => ({
      label: option.label,
      value: option.value,
    }));
    this.setState({
      selectedAgents: selectedAgentsArray,
    });
    console.log("finish", selectedOptions, this.state.selectedAgents);
    //this.setState({ selectedAgents: selectedOptions });
  };

  setTeam(e) {
    var siteTeam = { label: e.label, value: e.value };
    this.setState({ siteTeam: siteTeam });
  }
  changeCMCode(e) {
    var parent = e.label;
    var code = this.props.filters.pm_code.map((obj) => {
      return String(obj.parent_label).toUpperCase() ===
        String(parent).toUpperCase() ? (
        { label: obj.label, value: obj.value }
      ) : (
        <></>
      );
    });
    this.setState({ pmCode: code }, () => {
      console.log("hello state", this.state.pmCode);
    });
  }

  componentDidMount() {
    api.get("/api/sites/filters").then((response) => {
      if (response.data.success) {
        console.log("hello 2", response.data.agents);

        this.setState({ agents: response.data.agents });

        console.log("hello 3", response.data.agents);
      }
    });
    api.get("/api/sites/fetch/filter?limit=-1").then((response) => {
      if (response.data.success) {
        this.setState({ sites: response.data.sites });
        console.log("site getting", response.data.sites);
      }
    });
    api.get("/api/task_type/fetch").then((response) => {
      if (response.data.success) {
        this.setState({
          types: response.data.tasks,
        });

        console.log("hello tasks ", response.data.tasks);
      }
    });
    api.get("/api/form/filters").then((response) => {
      if (response.data.success) {
        this.setState({ wilayas: response.data.wilayas });
      }
    });
  }

  uploadFile = ({ target: { files } }) => {
    console.log("files", files[0]);
    let data = new FormData();

    data.append("file", files[0]);
    console.log("data files", data);
    api.post("/api/settings/files/new", data).then((response) => {
      console.log("data response", response);

      if (response.data.success) {
        //console.log('file_id',this.state.file_id);
        this.setState({ file_id: response.data.file_id });
      }
    });
    //   api.get("/api/settings/files").then((response)=>{console.log('files last',response);
    //        this.setState({files:response.data.files});
    //        console.log('files stqtes',this.state.files);
    //        let last_id=this.state.files.pop();
    //        console.log('last_id',last_id);
    //       this.setState({file_id:last_id.id});
    //       console.log('file_id',this.state.file_id);

    // });
    // api.post("/api/settings/files/new",data).then(res=>{console.log('resultat file',res)});
    // this.state.files=
  };

  render() {
    if (this.state.alert_message === "success") {
      return <Success />;
    } else {
      const fileid = this.state.file_id;
      console.log("selected_type", this.state.selected_type);

      return (
        <form onSubmit={this.formSubmit}>
          {/* <h5>Planifier une tâche</h5> */}
          {/* */}
          <div className="form-group">
            {this.state.alert_message === "Error" && (
              <Failure message={this.state.messageError} />
            )}
            <h4 className={"mb-4"}>
              <Trans i18nKey="pm_information" />
            </h4>

            <div className={"form-group"} style={{ display: "none" }}>
              <label className={"font-exp"}>Type * </label>

              <select
                name={"task_type"}
                className={"form-control"}
                onChange={this.handleChangeSelected}
              >
                {this.state.types.map((type) =>
                  // type.type_value == "delivery" ||
                  //type.type_value == "inventory" ||
                  type.type_value == "ssr" ? (
                    // type.type_value == "installation"
                    <option key={type.task_id} value={type.type_value}>
                      {type.type_value}
                    </option>
                  ) : (
                    ""
                  )
                )}
              </select>
            </div>

            <div className={"form-group"}>
              <label className={"font-exp"}>Type Demande * </label>

              <select
                name={"demande_type"}
                className={"form-control"}
                onChange={(e) =>
                  this.setState({ type_demande: e.target.value })
                }
              >
                {type_demande.map((type) => (
                  //   // type.type_value == "delivery" ||
                  //  //type.type_value == "inventory" ||
                  //   type.type_value == "ssr"
                  //   // type.type_value == "installation"
                  //   ?
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* {this.state.selected_type === 'installation' &&
            <div className={"form-group"}>
              <label className={"font-exp"} >Type Installation  </label>

              <select name={"installation_type"} className={"form-control"}>
                <option key='NOUVEAU' value='nouveau'>nouveau site</option>
                <option key='MODERNISATION' value='>modernisation'>modernisation d'un site</option>
                <option key='MW' value='mw'>mw</option>
              </select>
            </div>
          }

          {this.state.selected_type === 'delivery' &&

            <div className={"form-group mt-3"} >
              <label className=" font-exp" >PickUp  site: </label>
              <select name={"pickup_site"} className={"form-control"}>
                {this.state.sites.map((site) => <option key={site.site_id} value={site.site_id}>{site.site_name}</option>
                )}
              </select>

            </div>
          } */}

          {this.state.type_demande != "Nouveau Client" && (
            <div className={"form-group mt-3"}>
              <label className={"font-exp"}>Site *</label>

              <select name={"drop_site"} className={"form-control"}>
                {this.state.sites.map((site) => (
                  <option key={site.site_id} value={site.site_id}>
                    {site.site_name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {this.state.type_demande == "Nouveau Client" && (
            <div className={"form-group"}>
              <label>Nom de l'assuré</label>
              <input
                name="drop_site"
                className={"form-control"}
                id="description"
              />
            </div>
          )}

          {this.state.type_demande == "Nouveau Client" && (
            <div className={"form-group mt-3"}>
              <label className={"font-exp"}>Wilaya *</label>

              <select name={"wilaya"} className={"form-control"}>
                {this.state.wilayas &&
                  this.state.wilayas.map((wilaya) => (
                    <option key={wilaya.value} value={wilaya.value}>
                      {wilaya.value}
                    </option>
                  ))}
              </select>
            </div>
          )}

          {this.state.type_demande == "Nouveau Client" && (
            <div className={"form-group"}>
              <label>Adresse</label>
              <input
                name="adresse"
                className={"form-control"}
                id="description"
              />
            </div>
          )}

          {this.state.type_demande == "Nouveau Client" && (
            <div className={"form-group"}>
              <label>Activité</label>
              <input
                name="activite"
                className={"form-control"}
                id="description"
              />
            </div>
          )}

          {this.state.type_demande == "Nouveau Client" && (
            <div className={"form-group"}>
              <label>Valeur totale assurée</label>
              <input
                name="valeur"
                className={"form-control"}
                id="description"
              />
            </div>
          )}

          <div className={"form-group mt-3"}>
            <label className={"font-exp"}>Date *</label>
            <input
              id="date"
              required
              // defaultValue={site.site_next_pm}
              name={"planning_date"}
              type={"date"}
              className={"form-control"}
            />
          </div>
          {this.state.selected_type === "ssr" && (
            <div className={"form-group mt-3"}>
              <label className={"font-exp"}>
                Contrat d'assurance ( piece jointe){" "}
              </label>
              <input type="hidden" name={"file_id"} value={fileid} />
              <input
                type="file"
                className="form-control delivery-file-uploder"
                onChange={this.uploadFile}
              />
            </div>
          )}
          <div className={"form-group"}>
            <label>Description</label>
            <textarea
              name="instruction"
              className={"form-control"}
              id="description"
            />
          </div>

          <div className="form-group mt-3">
            <label className={"font-exp"} id="ag">
            Sélectionner des ingénieurs *
            </label>

            <div>
              {
                /*  <Select
                //classNamePrefix={"custom-select"}
                name={"selectedAgents"}
                /* options={this.state.agents} */
                /*  options={[ */
                // { label: "Tous", value: "" },
                /*    ...this.state.agents,
                ]} */
                /* cacheOptions
                defaultOptions */
                /*  isMulti
                placeholder="Agents"
                className={"d-block mt-1"} */
                // onChange={this.setAgent}
                /*  onChange={(selectedOptions) =>
                  this.setState({
                    selectedAgents: [
                      ...this.state.selectedAgents,
                      selectedOptions,
                    ],
                  })
                } */
                /*  />   */
              }
              <Select
                name={"selectedAgents"}
                options={[...this.state.agents]}
                isMulti
                placeholder="Agents"
                className={"d-block mt-1"}
                onChange={this.setSelectedAgents}
              />
            </div>
          </div>

          <div className="form-group mt-3">
            <input
              type="submit"
              value="Planifier"
              className={"btn btn-primary btn-sm mt-2"}
            />
          </div>
        </form>
      );
    }
  }
}
// export default Form;
export default connect(mapStateToProps, mapDispatchToProps)(Form);
