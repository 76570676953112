import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCheck,faTrash,faEdit,faSave,faTimes} from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";
import api from '../../../../Utils/Api';
const moment = require("moment");

const Maintenance = ({ data, active,agent,taskId, ...props }) => { 
  const [checkAdd,setCheckAdd]=useState('false');
 
  const toggle=()=>{
    setCheckAdd(!checkAdd);
  }
  const onCheckClick = async (e) => {
    e.preventDefault();

   let dataToSend={
    name:"maintenance",
    data:data,
    agent:agent,
    taskId:taskId,
   }
    console.log("oncheckclick",e,dataToSend)

    try {
      const response = await api.post("/api/rapport/new", dataToSend); // Use await
 
      if (response.data.success) {
      
        props.setOverlay({
          message: "Rapport mis à jour avec succés",
          label: "success",
          button: {
            type: "close",
            label: "OK",
            link: "javascript:void(0)",
          },
       
        });
        
      } else {
        // Handle API errors here
        console.error("API error:", response.data); // Log error details

        // Optionally display error message to user
      }
    } catch (err) {
      console.error("Error submitting form:", err); // Log errors
    } finally {
     /*  setIsLoading(false);  */ 
    }
  };  
  const url =
    data && data.location
      ? `https://www.google.com/maps/place/${data.location.lat},${data.location.lng}/@${data.location.lat},${data.location.lng},15z`
      : "";
     
  if (data)
    return (
      <>
        <h6>Maintenance (Fiche Technique)</h6>
        <div>
            <button className="edit-button" onClick={()=>toggle()}>
              <FontAwesomeIcon icon={faEdit} /> Edit
            </button>
            <button className="edit-button" onClick={()=>toggle()}>
              <FontAwesomeIcon icon={faCheck} />
            </button>
          </div>
        <br />

        <div className={checkAdd ? "ui-overlay " : "ui-overlay open"}>
        <div className={"ui-overlay-content delete-overlay col-md-4 offset-md-4"}>
          <button onClick={e => { toggle(); } } className={"ui-overlay-close btn btn-danger btn-sm"}>
          <FontAwesomeIcon icon={faTimes} />
          </button>
  
          Voulez-vous vraiment ajouter cette partie au rapport ?<br />
          Cette action est irreversible
          <div className={"delete-buttons"} onClick={e => { toggle(); } } style={{
            display:"flex",justifyContent:"space-evenly"}}>

          <button onClick={e => { toggle(); } } style={{paddingRight:"1rem"}} className={"btn btn-danger ml-2"}>No</button>
          <button onClick={(e)=>onCheckClick(e)}   style={{paddingRight:"1rem"}} className={"btn btn-info"}>Oui</button>
          </div>
        </div>
      </div>

        <h6>Orgaisation De La Maintenance</h6>
        <div className={"row col-md"}>
          <label>Equipe De Maintenance : </label>
          {data.equipe}
          {data.equipe == "Existence" && (
            <>
              <div className={"row col-md"}>
                <label>Structure : </label>
                {data.Structure}
                <label>Effectif : </label>
                {data.Effectif}
                <label>Profil : </label>
                {data.Profil}
              </div>
            </>
          )}
        </div>
        <div className={"row col-md"}>
          <label>Gestion : </label>
          {data.Gestion}
        </div>

        <h6>Phylosophie De La Maintenance</h6>
        <div className={"row col-md"}>
          <label>Type De Maintenance : </label>
          {data.Profil}
        </div>
        <div className={"row col-md"}>
          <label>Selon QUi : </label>
          {data.Gestion}
        </div>
        <h6>Principaux Controles Effectues</h6>
        <div className={"row col-md"}>
          <label>Service Concerné : </label>
          {data.Service_concerne}
        </div>
        <div className={"row col-md"}>
          <label>Base du Planning : </label>
          {data.Base_planning}
        </div>
        <div className={"row col-md"}>
          <label>Vérification effectuée : </label>
          {data.Vérification_effectuee}
        </div>
        <div className={"row col-md"}>
          <label>Régulation effectuée : </label>
          {data.Régulation_effectuee}
        </div>
        <div className={"row col-md"}>
          <label>Fréquence : </label>
          {data.Frequence}
        </div>
        <h6>
          Recours a la sous traitance et/ou vérification par organisme externe
          agrée
        </h6>
        <div className={"row col-md"}>
          <label>Equipements : </label>
          {data.Equipements}
        </div>
        <div className={"row col-md"}>
          <label>Fréquence De vérification : </label>
          {data.Fréquence_verification}
        </div>
        <div className={"row col-md"}>
          <label>Organisme De De vérification : </label>
          {data.Oragnisme_verification}
        </div>
        <div className={"row col-md"}>
          <label>Base De De vérification : </label>
          {data.Base_verification}
        </div>
        <h6>Arret Annuel</h6>
        <div className={"row col-md"}>
          <label>Arret Annuel : </label>
          {data.arret_annuel}
        </div>
        <div className={"row col-md"}>
          <label>Nature Arret : </label>
          {data.Nature_arret}
        </div>
        <div className={"row col-md"}>
          <label>Durée Arret : </label>
          {data.Duree_arret}
        </div>
        <div className={"row col-md"}>
          <label>Base de l' Arret : </label>
          {data.Base_arret}
        </div>
        <div className={"row col-md"}>
          <label>Objectif de l' Arret : </label>
          {data.objectif_arret}
        </div>
        <div className={"row col-md"}>
          <label>piece rechange : </label>
          {data.piece_rechange}
        </div>
        <div className={"row col-md"}>
          <label>piece rechange : </label>
          {data.piece_rechange}
        </div>
        <div className={"row col-md"}>
          <label>Nombre de magasin : </label>
          {data.nbr_magasin}
        </div>
        <div className={"row col-md"}>
          <label>Nature de PDR : </label>
          {data.Nature_PDR}
        </div>
        <div className={"row col-md"}>
          <label>Gestion de PDR : </label>
          {data.Gestion_PDR}
        </div>
        <div className={"row col-md"}>
          <label>Disponibilité de PDR : </label>
          {data.Disponibilite_PDR}
        </div>
        <div className={"row col-md"}>
          <label>Origine des PDR : </label>
          {data.Origine_PDR}
        </div>
      </>
    );
  else return <>Didn't clock in</>;
};

export default Maintenance;
