import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";

const CheckOrTime = ({ task }) => {
  let doneKey = null;
  // Iterate through the task object's properties
  for (const key in task) { 
    for (const id in task[key]){
    if ( task[key][id].done) {
        doneKey = key;
        break;
    }
  }
}
console.log("donekey===>",doneKey)
  return (
    doneKey ? (
      <span>
        <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faCheck} size={22} />
        
      </span>
    ) : (
      <FontAwesomeIcon color="#FF3B30" icon={faTimes} size={22} />
    )
  );
};

export default CheckOrTime;
