import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";
import {faCheck,faTrash,faEdit,faSave,faTimes} from "@fortawesome/free-solid-svg-icons";
import api from '../../../../Utils/Api';
 
import ApiService from '../../../../Utils/ApiService';

const moment = require("moment");

const Introduction = ({ data, active,agent,taskId,photos, ...props }) => {
  console.log("ddddddddddddd",photos)
  const [checkAdd,setCheckAdd]=useState('false');
 
  const toggle=()=>{
    setCheckAdd(!checkAdd);
  }
  const onCheckClick = async (e) => {
    e.preventDefault();

   let dataToSend={
    name:"introduction",
    data:data,
    agent:agent,
    taskId:taskId,
   }
    console.log("oncheckclick",e,dataToSend)

    try {
      const response = await api.post("/api/rapport/new", dataToSend); // Use await
 
      if (response.data.success) {
      
        props.setOverlay({
          message: "Rapport mis à jour avec succés",
          label: "success",
          button: {
            type: "close",
            label: "OK",
            link: "javascript:void(0)",
          },
       
        });
        
      } else {
        // Handle API errors here
        console.error("API error:", response.data); // Log error details

        // Optionally display error message to user
      }
    } catch (err) {
      console.error("Error submitting form:", err); // Log errors
    } finally {
     /*  setIsLoading(false);  */ 
    }
  };  
 
  const url =
    data && data.location
      ? `https://www.google.com/maps/place/${data.location.lat},${data.location.lng}/@${data.location.lat},${data.location.lng},15z`
      : "";

  if (data)
    return (
      < >
       
       <div className="d-flex justify-content-between">
          <h6>Introduction (Fiche Technique)</h6>
          <div>
            <button className="edit-button" onClick={()=>toggle()}>
              <FontAwesomeIcon icon={faEdit} /> Edit
            </button>
            <button className="edit-button" onClick={()=>toggle()}>
              <FontAwesomeIcon icon={faCheck} />
            </button>
          </div>
        </div>
        <br />
      
        <div className={checkAdd ? "ui-overlay " : "ui-overlay open"}>
        <div className={"ui-overlay-content delete-overlay col-md-4 offset-md-4"}>
          <button onClick={e => { toggle(); } } className={"ui-overlay-close btn btn-danger btn-sm"}>
          <FontAwesomeIcon icon={faTimes} />
          </button>
  
          Voulez-vous vraiment ajouter cette partie au rapport ?<br />
          Cette action est irreversible
          <div className={"delete-buttons"} onClick={e => { toggle(); } } style={{
            display:"flex",justifyContent:"space-evenly"}}>

          <button onClick={e => { toggle(); } } style={{paddingRight:"1rem"}} className={"btn btn-danger ml-2"}>No</button>
          <button onClick={(e)=>onCheckClick(e)}   style={{paddingRight:"1rem"}} className={"btn btn-info"}>Oui</button>
          </div>
        </div>
      </div>
        
        <div className={"row col-md"}>
        
          <label>Mandant : </label>
          {data.mandant}
        </div>
        <div className={"row col-md"}>
          <label>Assure : </label>
          {data.assure}
        </div>
        <div className={"row col-md"}>
          <label>Adresse : </label>
          {data.Adresse}
        </div>
        <div className={"row col-md"}>
          <label>wilaya : </label>
          {data.wilaya}
        </div>
        <div className={"row col-md"}>
          <label>Date de Visite : </label>
          {data.dateVisite}
        </div>
        <div className={"row col-md"}>
          <label>DRef Appel d'offre : </label>
          {data.refOffre}
        </div>
        <div className={"row col-md"}>
          <label>DRef Appel d'offre : </label>
          {data.objAssurance}
        </div>
        {photos && photos.length > 0
                  ? photos.map((photoItem) =>
                       
                        <PhotoItem photo={photoItem} />
                      
                    )
                  : ""}
      </>
    );
  else return <>Didn't clock in</>;
};

export default Introduction;
