import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";
import {faCheck,faTrash,faEdit,faSave,faTimes} from "@fortawesome/free-solid-svg-icons";
import api from '../../../../Utils/Api';
 
import ApiService from '../../../../Utils/ApiService';

const moment = require("moment");

const Surete = ({ data, active,agent,taskId, ...props }) => {
   console.log("Surete====>",Object.keys(data))
  const [checkAdd,setCheckAdd]=useState('false');
 
  const toggle=()=>{
    setCheckAdd(!checkAdd);
  }
  const onCheckClick = async (e) => {
    e.preventDefault();

   let dataToSend={
    name:"surete",
    data:data,
    agent:agent,
    taskId:taskId,
   }
    console.log("oncheckclick",e,dataToSend)

    try {
      const response = await api.post("/api/rapport/new", dataToSend); // Use await
 
      if (response.data.success) {
      
        props.setOverlay({
          message: "Rapport mis à jour avec succés",
          label: "success",
          button: {
            type: "close",
            label: "OK",
            link: "javascript:void(0)",
          },
       
        });
        
      } else {
        // Handle API errors here
        console.error("API error:", response.data); // Log error details

        // Optionally display error message to user
      }
    } catch (err) {
      console.error("Error submitting form:", err); // Log errors
    } finally {
     /*  setIsLoading(false);  */ 
    }
  };  
 
  const url =
    data && data.location
      ? `https://www.google.com/maps/place/${data.location.lat},${data.location.lng}/@${data.location.lat},${data.location.lng},15z`
      : "";

      if (data)
        return (
          <>
            
             <h6>Sureté </h6>
             <div>
            <button className="edit-button" onClick={()=>toggle()}>
              <FontAwesomeIcon icon={faEdit} /> Edit
            </button>
            <button className="edit-button" onClick={()=>toggle()}>
              <FontAwesomeIcon icon={faCheck} />
            </button>
          </div>
            <br />
            <div className={checkAdd ? "ui-overlay " : "ui-overlay open"}>
        <div className={"ui-overlay-content delete-overlay col-md-4 offset-md-4"}>
          <button onClick={e => { toggle(); } } className={"ui-overlay-close btn btn-danger btn-sm"}>
          <FontAwesomeIcon icon={faTimes} />
          </button>
  
          Voulez-vous vraiment ajouter cette partie au rapport ?<br />
          Cette action est irreversible
          <div className={"delete-buttons"} onClick={e => { toggle(); } } style={{
            display:"flex",justifyContent:"space-evenly"}}>

          <button onClick={e => { toggle(); } } style={{paddingRight:"1rem"}} className={"btn btn-danger ml-2"}>No</button>
          <button onClick={(e)=>onCheckClick(e)}   style={{paddingRight:"1rem"}} className={"btn btn-info"}>Oui</button>
          </div>
        </div>
      </div>
            <h6> Accés</h6>
           
          <div className={"row col-md-6"}>
            <label>Nombre d’accès   :  </label>
            {data.nombreAcces}
          </div>
    
     
          <div className={"row col-md-6"}>
            <label>Situation  :  </label>
            {data.situation}
          </div>
          <div className={"row col-md-6"}>
            <label>Accès principal :  </label>
            {data.accessPrincipal}
          </div> 
          <div className={"row col-md-6"}>
            <label>Accès secoundaire :  </label>
            {data.accessSecondaire}
          </div>
          <h6>Cloture </h6>
                
           
          <div className={"row col-md-6"}>
            <label>Type de mur :  </label>
            {data.typeMur}
          </div> 
          <div className={"row col-md-6"}>
            <label>Hauteur : </label>
            {data.hauteurMur}
          </div>
          <div className={"row col-md-6"}>
            <label>Fils barbelés : </label>
            {data.filsbarbeles}
          </div>
          <h6> Gardiennage </h6>
          <div className={"row col-md-6"}>
            <label>Equipe interne : </label>
            {data.EquipeInterne}
          </div>
          <div className={"row col-md-6"}>
            <label>Structure : </label>
            {data.Structure}
          </div>
          <div className={"row col-md-6"}>
            <label>Nombre : </label>
            {data.Nombre}
          </div>
          <div className={"row col-md-6"}>
            <label>Systéme de travail : </label>
            {data.Systéme_travail}
          </div>
          
          <div className={"row col-md-6"}>
            <label>Supervision: </label>
            {data.Supervision}
          </div>
          <div className={"row col-md-6"}>
            <label>Soustraitance : </label>
            {data.Soustraitance}
          </div>
          <div className={"row col-md-6"}>
            <label>Nom du sous traitant : </label>
            {data.nomSousTraitant}
          </div>
          <div className={"row col-md-6"}>
            <label>Nombre : </label>
            {data.NombreSousTraitant}
          </div>
         
          <div className={"row col-md-6"}>
            <label>Supervision : </label>
            {data.Supervisiongardiennage}
          </div>
          <h6> Contrôle des mouvements </h6>
           
          
          <div className={"row col-md-6"}>
            <label> Contrôle des mouvements : </label>
            {data.controlemouvement}
          </div>
         <h6> Caméras </h6>
           <div className={"row col-md-6"}>
            <label>Sytéme de telesurveillance  : </label>
            {data.telesurveillance}
          </div>
        
       
          <div className={"row col-md-6"}>
            <label>Nombre des caméras : </label>
            {data.NombreCamera}
          </div>
            <div className={"row col-md-6"}>
            <label>Type de caméras : </label>
            {data.typeCamera}
          </div>
          <div className={"row col-md-6"}>
            <label>Emplacement  : </label>
            {data.EmplacementCamera}
          </div>
          <div className={"row col-md-6"}>
            <label>Durée d'enregistrement  : </label>
            {data.dureeEnregistrement}
          </div>
          <div className={"row col-md-6"}>
            <label> Supervision  : </label>
            {data.SupervisionCamera}
          </div>
          <div className={"row col-md-6"}>
            <label> Emplacement de la salle de supervision  : </label>
            {data.emplacementSupervision}
          </div>
         
          
        <h6>Système anti-intrusion </h6>
    
       
          <div className={"row col-md-6"}>
            <label>Système anti-intrusion  : </label>
            {data.antiIntrusion}
          </div>
    
          <div className={"row col-md-6"}>
            <label>Existence  : </label>
            {data.systemeAntiIntrusion}
          </div>
    
          <div className={"row col-md-6"}>
            <label>Etat  : </label>
            {data.Etat}
          </div>
    
          <div className={"row col-md-6"}>
            <label>type  : </label>
            {data.type}
          </div>
          <div className={"row col-md-6"}>
            <label>Frequence  : </label>
            {data.frequence}
          </div>
          <div className={"row col-md-6"}>
            <label>Emplacement  : </label>
            {data.Emplacement}
          </div>
         
          <div className={"row col-md-6"}>
            <label>Vérifié  : </label>
            {data.Vérifié}
          </div>
          </>
        );
      else return <>Didn't clock in</>;
};

export default Surete;
