import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo, faFileExport, faChevronDown, faTrash, faAngleRight, faCheck, faMapMarker, faLock, faSpinner, faPlus , faCheckDouble, faTimes, faCheckCircle, faStickyNote, faReply, faCheckSquare} from '@fortawesome/free-solid-svg-icons';
import MoreInfoDialog, { Item } from '../../../Utils/MoreInfoDialog';
import moment from 'moment';
import CheckOrTime from './../components/CheckOrTime';
import Restricted from '../../../Utils/Restricted';
import api from '../../../Utils/Api';
import SSRTests from './Tests';
import RenderComponent from '../../../Utils/RenderComponent';
import Constants from '../../../Utils/Constants';
import ApiService from '../../../Utils/ApiService';
import { useSelector } from 'react-redux';
import NotesReturn from "./Tests/NotesReturn";
import SubSite from './Tests/SubSite';
import Introduction from './Tests/Introduction';
import Maintenance from './Tests/Maintenance';
import Situation from './Tests/Situation';
import PAE from './Tests/PAE';
import 'leaflet/dist/leaflet.css' 
import * as L from 'leaflet'
import '@geoman-io/leaflet-geoman-free';  
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'; 

import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import { act } from 'react'; 
import Construction from './Tests/Construction';
import surete from './Tests/Surete';
import Utilities from './Tests/Utilities';
import Stockage from './Tests/Stockage';
import PAP from './Tests/PAP';


const TaskRow = ({ task, key, /*exportPM,*/ deleteTask, returnTask, closeTask, acceptTask, rejectTask, showNewReplan, addDataTask, ...props }) => {

  // const i = key;
  
  const { session } = useSelector(state => ({ session: state.session }));
  const [activeTest, selectTest] = useState("clockin")
  const [showDetail, setShowDetail] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const exportForm = useRef(null);
  const detailInput = useRef(null);
  const [polColor , setPolColor] = useState( '#0fcc04')
 
  const selectTask = (show = true) => { 
    if (!showDetail) {
      setLoading(true);
      ApiService.fetchVdrTaskDetail(task.task_id)

        .then(res => { 
          if (res.data.success && res.data.total > 0) {
            setDataLoaded(true);
            console.log("select task===>",res.data.tasks.task_tests?.introduction)
             //setData(res.data.tasks[1]);
             setData(res.data.tasks);
             task.task_tests=res.data.tasks.task_tests
             /* if(task.task_agent_id==res.data.tasks[0].task_agent_id){
              setData(res.data.tasks[0]);
            }else{
             setData(res.data.tasks[1]);
            } */
          }
          setLoading(false);
        })
    }
    setShowDetail(!showDetail)
  }


  // const  loadKML = () => {
  //     //setLoading(true)
  //     api.get(`/api/cosite/getData/${1}`).then(res => {
  //       if(res.data){
  //             let converted = res.data.data
  //             let zoom = res.data.zoom ? res.data.zoom : 5
  //             let lat = res.data.lat ? res.data.lat : 36.000
  //             let lng = res.data.lng ? res.data.lng : 2.09
  //            // setKml(converted)
  //             const container = document.getElementById('mapId'+1)
  //             if( container ){

  //                 let  map = L.map('mapId'+1 , 
  //                                     { style: {height: '220px'}}).setView([lat, lng], zoom  )
  //                 console.log('map => '+ map)
  //                 let buildLayer = L.geoJSON(converted , 
  //                     {
  //                     style: function(feature) {
  //                         return {
  //                             color : feature && feature.properties && feature.properties.fill ? feature.properties.fill : polColor, 
  //                             weight: feature && feature.properties && feature.properties['stroke-width'] ?  feature.properties['stroke-width'] : 1
  //                         };
  //                         // switch (feature.properties.name) {
  //                         //     case 'Marker 2': return {color: "#F44336"};
  //                         //     case 'Democrat':   return {color: "#0000ff"};
  //                         // }
  //                     }}
  //                     )
  //                    // setFeatures([converted])

  //                    // L.control.layers(baseMaps).addTo(map);
                    

  //                     L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
  //                         attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  //                     }).addTo(map);
  //                     var drawnItems = new L.FeatureGroup();  
  //                     map.addLayer(drawnItems);
  //                     // setFeatureGroup(drawnItems)
  //                     buildLayer.eachLayer(function (layer) {
  //                         drawnItems.addLayer(layer);
  //                         L.PM.reInitLayer(layer);
                          
  //                     }); 
  //                     // setBuildL(buildLayer)
  //                     // setMapContainer(map)
  //                     // DrawingContainer(map, drawnItems)
  //             }
  //             //setLoading(false)
  //         }
  //     });
  // }
  const exportPM = (anomalies) => {

    setLoading(true);
    if (anomalies) {
      exportForm.current.action = Constants.apiEndPoints.EXPORT_SSR_TASK_ANOMALIES(task.task_id);
    } else {
      exportForm.current.action = Constants.apiEndPoints.EXPORT_SSR_TASK(task.task_id);
    }

    if (!dataLoaded)
      ApiService.fetchSSRTaskDetail(task.task_id)
        .then(res => {
          if (res.data.success && res.data.total > 0) {
            setDataLoaded(true);
            setData(res.data.tasks[0]);
            detailInput.current.value = JSON.stringify(res.data.tasks[0]);
            exportForm.current.submit();
            setLoading(false);
          }
          setLoading(false);
        })
    else {
      detailInput.current.value = JSON.stringify(data);
      exportForm.current.submit();
      setLoading(false);
    }
  }

  const exportPMXLSX = (anomalies) => {

    setLoading(true);
    
    exportForm.current.action = Constants.apiEndPoints.EXPORTPDF_SSR_TASK(task.task_id);
    

    if (!dataLoaded)
      ApiService.fetchSSRTaskDetail(task.task_id)
        .then(res => {
          if (res.data.success && res.data.total > 0) {
            setDataLoaded(true);
            setData(res.data.tasks[0]);
            detailInput.current.value = JSON.stringify(res.data.tasks[0]);
            exportForm.current.submit();
            setLoading(false);
          }
          setLoading(false);
        })
    else {
      detailInput.current.value = JSON.stringify(data);
      exportForm.current.submit();
      setLoading(false);
    }
  }

  const exportPMBatXLSX = (anomalies) => {
      // domtoimage.toBlob(document.getElementById('mapId'+1))
      //   .then(function (blob) {
      //     saveAs(blob, 'my-node.png');
            
      //   });


    setLoading(true);
    
    exportForm.current.action = Constants.apiEndPoints.EXPORTXLSXZONE_SSR_TASK(task.task_id);
    

    if (!dataLoaded)
      ApiService.fetchSSRTaskDetail(task.task_id)
        .then(res => {
          if (res.data.success && res.data.total > 0) {
            setDataLoaded(true);
            setData(res.data.tasks[0]);
            detailInput.current.value = JSON.stringify(res.data.tasks[0]);
            exportForm.current.submit();
            setLoading(false);
          }
          setLoading(false);
        })
    else {
      detailInput.current.value = JSON.stringify(data);
      exportForm.current.submit();
      setLoading(false);
    }
  }
  onchange = (id, test, field, value , isTable = false , isPower = false , att = '') => { 
   // const value = e.target ? e.target.value : e.value;
   
    let d = {pm:  id , test: test, field: field, value : value , isTable : isTable , isPower: isPower , att: att}
    setLoading(true);
  /*   ApiService.editTest(d)
        .then(res => {
          if (res.data.success ) {
            setDataLoaded(true);
            let array = data
            array.task_tests['access'].data  = res.data.data
            setData(array);
            // detailInput.current.value = JSON.stringify(res.data.tasks[0]);
            // exportForm.current.submit();
            setLoading(false);
          }
          setLoading(false);
        }) */
  }

 
    
  const stepsDone=task.done_steps
     
  const stepsDones = Object.keys(task.task_tests).
      filter((el) => el !== "clockin" && 
      task.task_tests[el].data 
      && task.task_tests[el].done);
  const stepsCount = 9
   console.log("step done===>",Object.keys(task),Object.keys(task.task_tests) )
  //const stepsCount = 9 
  //console.log("data.task_tests", (data.task_tests)!=undefined ? Object.keys(data.task_tests.introduction) : 0);
   

  let doneCount = 0; // To count the number of tasks done
 
  // Iterate over each key in the ask_tests object
  for (const key in task.task_tests) {
    if (task.task_tests.hasOwnProperty(key)) {
      // Get the sub-object (e.g., introduction, maintenance, etc.)
      const subObject = task.task_tests[key][task.task_agent_id][key];
      
      console.log("subObject",Object.keys(subObject))
      // Check if the sub-object has a "done" field
      if (subObject.done !== undefined) {
        if (subObject.done === true) {
          doneCount++; // Increment if done is true
        }
      } else {
        // Check if it has nested objects like "PAE"
        for (const nestedKey in subObject) {
          if (subObject[nestedKey].done !== undefined) {
            if (subObject[nestedKey].done === true) {
              doneCount++; // Increment if done is true
            }
          }
        }
      }
    }
  }
  
  console.log(`Number of completed tasks: ${doneCount}`);
  





















  const renderTestComponent = (testKey) => {
    console.log("renderTestComponent render >Test Component",testKey)
    // Import the appropriate component based on testKey
    switch (testKey) {
      case 'introduction':
        return Introduction;
      case 'maintenance':
        return Maintenance  ;
        case 'situation':
          return Situation;
          case 'construction':
          return Construction;
          case 'PAE':
            return PAE;
            case 'surete':
              return surete;
              case 'Utilites':
                return Utilities;
                case 'stockage':
                  return Stockage;
                  case 'PAP':
                    return PAP;
              
      // Add additional cases here for other test components (e.g., SubSite, NotesReturn)
      default:
        return null; // Handle unknown test keys gracefully
    }
  };







  return (
    <React.Fragment>
      <form method="post" ref={exportForm} target={"_blank"} >
        <input type="hidden" name={"pm"} ref={detailInput} />
        <input type="hidden" name={"token"} value={session.user.token} />
      </form>
      <div className={"row "}>
        <div className={"col-md-12"}>
          <div className={"row ctable-row"}>
            <div className={"col-md-1 table-space-between"}>
              <span>{task.task_id}</span>
              {task.task_status ==="ongoing" && <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faSpinner} />}
              {!task.task_status && <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faCheck} />}
              {task.task_status === "return" && <FontAwesomeIcon color="orange" icon={faUndo} />}
              {task.task_status === "closed" && <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faCheckDouble}  />}
              {task.task_status === "reject" && <FontAwesomeIcon color="#d9534f" icon={faTimes} />}
              {task.task_status === "correct" && <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faCheckSquare} />}
              {task.task_status ==="accept" && <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faCheckCircle} />}
             
              {/* {!task.task_status ? <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faCheck} /> : task.task_status === "return" ? <FontAwesomeIcon color="orange" icon={faUndo} /> : ( task.task_status === "reject" ? <FontAwesomeIcon color="rgb(48, 209, 88)" icon={fa} /> : <FontAwesomeIcon color="rgb(48, 209, 88)" icon={faSpinner} />)} */}
            </div>

            <div className={"col-md-2"}>
              <a className={"site-link"} href={"/agents/" + task.task_agent_id}>{task.task_agent}</a>
            </div>

            <div className={"col-md-1"}>
              <a href={"/SSR/sites/detail/" + task.task_site_id} style={{ color: "inherit" }} className={"site-link"}>
                {task.task_site_code}
              </a>
            </div>

            <div className={"col-md-2"}>
              {task.owner}
            </div>

            <div className={"col-md-2"}>
              <span className={"task-plan-col"}>
                <span className={"line task-" + task.task_delay}>
                  <span className={"line-label"}>Créé</span>
                  <span className={"line-value"}>{task.task_created}</span>
                </span>
                <span className={"line"}>
                  <span className={"line-label"}>Plan</span>
                  <span className={"line-value"}>{task.task_plan}</span>
                </span>
              </span>
            </div>

            <div className={"col-md-2 task-tests-col"}>
            {/*   <span>{stepsDone.length + "/"+stepsCount}</span> */}
            <span>{doneCount + "/"+stepsCount}</span>
              {/* {task.task_tests.anomalies && task.task_tests.anomalies.data && task.task_tests.anomalies.data.items.length > 0 ? <span className={"task-count-anomalies"}>{task.task_tests.anomalies.data.items.length} anomalies</span> : ""} */}
              {/* {task.task_tests.anomalies && task.task_tests.anomalies.data && task.task_tests.anomalies.data.items.filter(el => el.resolved).length > 0 ? <span className={"task-count-anomalies"}>{task.task_tests.anomalies.data.items.filter(el => el.resolved).length} résolues </span> : ""} */}
            </div>

            <div className={"col-md-2 task-details-col"}>
              {task.geo && task.geo.lat && (
                <a href={"https://www.google.com/maps/place/" + task.geo.lat + "," + task.geo.lng + "/@" + task.geo.lat + "," + task.geo.lng + ",15z"} target='_blank' className="task-action-btn" >
                  <FontAwesomeIcon icon={faMapMarker} />Localisation
                </a>
              )}

              <button onClick={() => selectTask(!showDetail)} className="task-action-btn" ><FontAwesomeIcon icon={isLoading ? faSpinner : faChevronDown} spin={isLoading} /> Info</button>

              <MoreInfoDialog title={"Plus"}>

                <Restricted scope={"pm"} permission={"export_task"}>
                  <Item onClick={() => exportPM()} className="task-action-btn" ><FontAwesomeIcon className={"dialog-icon"} icon={faFileExport} /> Export</Item>
                </Restricted>

                {/* <Restricted scope={"pm"} permission={"export_task"}>
                  <Item onClick={() => exportPMXLSX()} className="task-action-btn" ><FontAwesomeIcon className={"dialog-icon"} icon={faFileExport} /> Export XLSX</Item>
                </Restricted> */}
               {/*   <Restricted scope={"pm"} permission={"export_task"}>
                  <Item onClick={() => exportPMBatXLSX()} className="task-action-btn" ><FontAwesomeIcon className={"dialog-icon"} icon={faFileExport} /> Export XLSX</Item>
                </Restricted> */}

                <Restricted scope={"pm"} permission={"delete_task"}>
                  <Item onClick={() => deleteTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon danger"} icon={faTrash} /> Supprimer</Item>
                </Restricted>

                <Restricted scope={"pm"} permission={"close_task"}>
                  <Item onClick={() => closeTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon"} icon={faLock} />Cloturer</Item>
                </Restricted>

                <Restricted scope={"pm"} permission={"return_task"}>
                  <Item onClick={() => returnTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon"} icon={faUndo} />Retourner</Item>
                </Restricted>
                {((session.user.role === "manager" && session.user.owner == 'ooredoo')  || session.user.role === "admin") &&
                  <Item onClick={() => acceptTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon"} icon={faCheckDouble} />Accepter</Item>
                }
                {((session.user.role === "manager" && session.user.owner == 'ooredoo')  || session.user.role === "admin") &&
                  <Item onClick={() => rejectTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon"} icon={faTimes} />Rejeter</Item>
                }

                <Item onClick={() => addDataTask(task.task_id, task.data_m)}><FontAwesomeIcon className={"dialog-icon"} icon={faPlus} />Ajouter Données</Item>
                {/* <Item onClick={() => planifierTask(task.task_id)}><FontAwesomeIcon className={"dialog-icon"} icon={faCheckDouble} />Plannifier</Item> */}

                {/* <Restricted scope={"pm"} permission={"read_task"}>
                  <Item onClick={() => exportPM(true)}><FontAwesomeIcon className={"dialog-icon"} icon={faFileExport} />Export NOK</Item>
                </Restricted>
                  */}
                <Restricted scope={"pm"} permission={"export_task"}>
                  <Item onClick={() => showNewReplan(task.task_id)} className="task-action-btn" ><FontAwesomeIcon className={"dialog-icon"} icon={faReply} /> Réassigner </Item>
                </Restricted> 

                
              </MoreInfoDialog>
            </div>
          </div>

          {showDetail && dataLoaded && data.task_tests && (
            <div className={"animateRow row"}>
              
              <div className={"pm-wrapper col-md-12 row no-gutters"}>
                <div className={"col-md-3 pm-sidebar"}>
                  <ul>
                  {SSRTests.map(test => (
                      <li className={activeTest === test.key ? "active" : ""}>
                       
                        <a onClick={e => selectTest(test.key)}>
                         
                          <CheckOrTime   task={task.task_tests[test.key]} />
                          {test.name}  
                          <FontAwesomeIcon icon={faAngleRight} size={22} />
                        </a>
                      </li>
                    ))}  
                      
                  
                  </ul>
                </div>

                <div className={"col-md-9 pm-content"}>
                {SSRTests.map((test) => (
                  
                  <div key={test.key} className={"pm-test-single"}>
                
  {data.task_tests?.[test.key] && (
                      <>
                        {Object.entries(data.task_tests[test.key]).map(([key, value]) => (
                         
                         /* className={activeTest === test.key ? "active" : ""} */
                         <p key={key}  style={{ display: "table-cell",paddingRight:"2rem", }}> 
           
    
       
       {activeTest==test.key &&  
    
<>
 
 <RenderComponent component={renderTestComponent(activeTest)}

 data={value[test.key].data}  
 photos={value[test.key].photos}
 onchange={onchange} 
 agent={key}
 taskId={task.task_id}
 
 /> 

</>
       }
 
 {/* 
 { test.key=="maintenance" ?  
                           
                           <RenderComponent component={Maintenance} 
                             data={value[test.key].data}  
                             onchange={onchange} 
                             name={test.key}
                           />  
                           :
                           (<></>)
                                                 } */}


 
                        </p>
                         
                        ))}
                      </>
                    )}

                  </div>
                ))}

                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default TaskRow;
