import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faMapMarker } from "@fortawesome/free-solid-svg-icons";
import PhotoItem from "../../../../Utils/PhotoItem";
import {faCheck,faTrash,faEdit,faSave,faTimes} from "@fortawesome/free-solid-svg-icons";
import api from '../../../../Utils/Api';
 
import ApiService from '../../../../Utils/ApiService';
 

const moment = require("moment");

const Utilities = ({ data, active,agent,taskId, ...props }) => {
  const [checkAdd,setCheckAdd]=useState('false');
 
  const toggle=()=>{
    setCheckAdd(!checkAdd);
  }
  const onCheckClick = async (e) => {
    e.preventDefault();

   let dataToSend={
    name:"Utilites",
    data:data,
    agent:agent,
    taskId:taskId,
   }
    console.log("oncheckclick",e,dataToSend)

    try {
      const response = await api.post("/api/rapport/new", dataToSend); // Use await
 
      if (response.data.success) {
      
        props.setOverlay({
          message: "Rapport mis à jour avec succés",
          label: "success",
          button: {
            type: "close",
            label: "OK",
            link: "javascript:void(0)",
          },
       
        });
        
      } else {
        // Handle API errors here
        console.error("API error:", response.data); // Log error details

        // Optionally display error message to user
      }
    } catch (err) {
      console.error("Error submitting form:", err); // Log errors
    } finally {
     /*  setIsLoading(false);  */ 
    }
  };   
 
  const url =
    data && data.location
      ? `https://www.google.com/maps/place/${data.location.lat},${data.location.lng}/@${data.location.lat},${data.location.lng},15z`
      : "";

      if (data)
        return (
          <>
            {data?.transformateurData && (
              <>
                <h6>Transformateur (Fiche Technique)</h6>
            <div>
            <button className="edit-button" onClick={()=>toggle()}>
              <FontAwesomeIcon icon={faEdit} /> Edit
            </button>
            <button className="edit-button" onClick={()=>toggle()}>
              <FontAwesomeIcon icon={faCheck} />
            </button>
          </div>
                <br />
                <div className={checkAdd ? "ui-overlay " : "ui-overlay open"}>
        <div className={"ui-overlay-content delete-overlay col-md-4 offset-md-4"}>
          <button onClick={e => { toggle(); } } className={"ui-overlay-close btn btn-danger btn-sm"}>
          <FontAwesomeIcon icon={faTimes} />
          </button>
  
          Voulez-vous vraiment ajouter cette partie au rapport ?<br />
          Cette action est irreversible
          <div className={"delete-buttons"} onClick={e => { toggle(); } } style={{
            display:"flex",justifyContent:"space-evenly"}}>

          <button onClick={e => { toggle(); } } style={{paddingRight:"1rem"}} className={"btn btn-danger ml-2"}>No</button>
          <button onClick={(e)=>onCheckClick(e)}   style={{paddingRight:"1rem"}} className={"btn btn-info"}>Oui</button>
          </div>
        </div>
      </div>
                <div className={"row col-md"}>
                  <label>Nombre de lignes : </label>
                  {data.transformateurData.transformateur_nbl}
                </div>
                <div className={"row col-md"}>
                  <label>Tension électrique : </label>
                  {data.transformateurData.transformateur_tensionElectrique}
                </div>
                <div className={"row col-md"}>
                  <label>Nombre de transformateurs : </label>
                  {data.transformateurData.transformateur_Nombre}
                </div>
                <div className={"row col-md"}>
                  <label>Locaux des transformateurs : </label>
                  {data.transformateurData.transformateur_locaux}
                </div>
                <div className={"row col-md"}>
                  <label>Verouillage des locaux : </label>
                  {data.transformateurData.transformateur_verouillage_locaux}
                </div>
    
                <div className={"row col-md"}>
                  <label>Accés : </label>
                  {data.transformateurData.transformateur_accés}
                </div>
                <div className={"row col-md"}>
                  <label>Marque : </label>
                  {data.transformateurData.Marque}
                </div>
                <div className={"row col-md"}>
                  <label>Puissance : </label>
                  {data.transformateurData.puissance}
                </div>
                <div className={"row col-md"}>
                  <label>Numéro de série /modèle : </label>
                  {data.transformateurData.numero_serie}
                </div>
                <div className={"row col-md"}>
                  <label>Date de fabrication : </label>
                  {data.transformateurData.date_de_fabrication}
                </div>
                <div className={"row col-md"}>
                  <label>Backup en cas de coupures électriques : </label>
                  {data.transformateurData.backup_coupures_electriques}
                </div>
              </>
            )}
            {data?.Stabilisateur && (
              <>
                <h6>Stabilisateur (Fiche Technique)</h6>
                <br />
                <div className={"row col-md"}>
                  <label>Les équipements reliés au stabilisateur : </label>
                  {data.Stabilisateur.equipements_relies_stabilisateur}
                </div>
                <div className={"row col-md"}>
                  <label>nombre de stabilisateur : </label>
                  {data.Stabilisateur.nombre_stabilisateur}
                </div>
                <div className={"row col-md"}>
                  <label>Marque : </label>
                  {data.Stabilisateur.Marque}
                </div>
                <div className={"row col-md"}>
                  <label>Puissance : </label>
                  {data.Stabilisateur.puissance}
                </div>
                <div className={"row col-md"}>
                  <label>Date de fabrication : </label>
                  {data.Stabilisateur.date_de_fabrication}
                </div>
                <div className={"row col-md"}>
                  <label>Numéro de série : </label>
                  {data.Stabilisateur.numero_serie}
                </div>
              </>
            )}
            <h6>Centrale électrique </h6>
            {data?.TurbogénérateursData && (
              <>
                <h6> Turbogénérateurs</h6>
                <br />
                <div className={"row col-md"}>
                  <label>type : </label>
                  {data.TurbogénérateursData.type}
                </div>
                <div className={"row col-md"}>
                  <label>Marque : </label>
                  {data.TurbogénérateursData.Marque}
                </div>
                <div className={"row col-md"}>
                  <label>vitesse : </label>
                  {data.TurbogénérateursData.Vitesse}
                </div>
                <div className={"row col-md"}>
                  <label>Puissance : </label>
                  {data.TurbogénérateursData.puissance}
                </div>
                <div className={"row col-md"}>
                  <label>Date de fabrication : </label>
                  {data.TurbogénérateursData.date_de_fabrication}
                </div>
                <div className={"row col-md"}>
                  <label>Numéro de série /modèle : </label>
                  {data.TurbogénérateursData.numero_serie}
                </div>
                <div className={"row col-md"}>
                  <label>Nombre d'étage : </label>
                  {data.TurbogénérateursData.Nombre_etage}
                </div>
              </>
            )}
            {data?.ElévateursData && (
              <>
                <h6> Elévateurs </h6>
    
                <div className={"row col-md"}>
                  <label>Nombre de Transformateurs élévateurs : </label>
                  {data.ElévateursData.transformateur_elevateurs}
                </div>
                <div className={"row col-md"}>
                  <label>Marque : </label>
                  {data.ElévateursData.Marque}
                </div>
                <div className={"row col-md"}>
                  <label>Puissance : </label>
                  {data.ElévateursData.puissance}
                </div>
                <div className={"row col-md"}>
                  <label>Date de fabrication : </label>
                  {data.ElévateursData.date_de_fabrication}
                </div>
                <div className={"row col-md"}>
                  <label>Numéro de série /modèle : </label>
                  {data.ElévateursData.numero_serie}
                </div>
              </>
            )}
            {data?.transformateursAbaisseursTensionData && (
              <>
                <h6> transformateurs abaisseurs </h6>
    
                <div className={"row col-md"}>
                  <label>Nombres transformateurs abaisseurs de tension : </label>
                  {
                    data.transformateursAbaisseursTensionData
                      .nombre_transformateur_abaisseurs_tension
                  }
                </div>
                <div className={"row col-md"}>
                  <label>Marque : </label>
                  {data.transformateursAbaisseursTensionData.Marque}
                </div>
                <div className={"row col-md"}>
                  <label>Puissance : </label>
                  {data.transformateursAbaisseursTensionData.puissance}
                </div>
                <div className={"row col-md"}>
                  <label>Date de fabrication : </label>
                  {data.transformateursAbaisseursTensionData.date_de_fabrication}
                </div>
                <div className={"row col-md"}>
                  <label>Numéro de série /modèle : </label>
                  {data.transformateursAbaisseursTensionData.numero_serie}
                </div>
              </>
            )}
            {data?.TgbtData && (
              <>
                <h6> ARMOIRES ELECTRIQUES /TGBT </h6>
    
                <div className={"row col-md"}>
                  <label>Installation des armoires : </label>
                  {data.TgbtData.installation_aromires}
                </div>
                <div className={"row col-md"}>
                  <label>Passage des câbles : </label>
                  {data.TgbtData.passage_cables}
                </div>
              </>
            )}
            {data?.GroupeElectriqueData && (
              <>
                <h6> Groupe électrogène </h6>
    
                <div className={"row col-md"}>
                  <label>Nombre : </label>
                  {data.GroupeElectriqueData.centre_electrique_nb_turbogénérateurs}
                </div>
                <div className={"row col-md"}>
                  <label>Basculement : </label>
                  {data.GroupeElectriqueData.groupe_electorgene_basculement}
                </div>
    
                <div className={"row col-md"}>
                  <label>Temps de basculement : </label>
                  {data.GroupeElectriqueData.temps_de_basculement}
                </div>
                <div className={"row col-md"}>
                  <label>Equipements reliés GE : </label>
                  {data.GroupeElectriqueData.groupe_electorgene_equipements}
                </div>
                <div className={"row col-md"}>
                  <label>Marque : </label>
                  {data.GroupeElectriqueData.Marque}
                </div>
                <div className={"row col-md"}>
                  <label>Puissance : </label>
                  {data.GroupeElectriqueData.puissance}
                </div>
                <div className={"row col-md"}>
                  <label>Date de fabrication : </label>
                  {data.GroupeElectriqueData.date_de_fabrication}
                </div>
                <div className={"row col-md"}>
                  <label>Reserve de gasoil : </label>
                  {data.GroupeElectriqueData.groupe_electorgene_remplissage}
                </div>
                <div className={"row col-md"}>
                  <label>Numéro de série /modèle : </label>
                  {data.GroupeElectriqueData.numero_serie}
                </div>
                <div className={"row col-md"}>
                  <label>Remplissage de la réserve : </label>
                  {data.GroupeElectriqueData.reserve_de_gasoil}
                </div>
                <div className={"row col-md"}>
                  <label>Tests de fonctionnement : </label>
                  {data.GroupeElectriqueData.groupe_electorgene_testFonctionement}
                </div>
              </>
            )}
            {data?.GasoilData && (
              <>
                <h6> Gasoil </h6>
                <div className={"row col-md"}>
                  <label>type : </label>
                  {data.GasoilData.gasoil}
                </div>
                <div className={"row col-md"}>
                  <label>Capacité : </label>
                  {data.GasoilData.Capacité}
                </div>
                <div className={"row col-md"}>
                  <label>Utilisation : </label>
                  {data.GasoilData.Utilisation}
                </div>
    
                <div className={"row col-md"}>
                  <label>Mode stockage : </label>
                  {data.GasoilData.gasoil_mode_stockage}
                </div>
                <div className={"row col-md"}>
                  <label>Implantation de la réserve : </label>
                  {data.GasoilData.implantation_de_reserve}
                </div>
              </>
            )}
            {data?.OnduleurData && (
              <>
                <h6> Onduleur </h6>
                <div className={"row col-md"}>
                  <label>Nombre d'onduleurs : </label>
                  {data.OnduleurData.nombre_onduleurs}
                </div>
                <div className={"row col-md"}>
                  <label>Equipements reliés aux onduleurs : </label>
                  {data.OnduleurData.Onduleur_equipement}
                </div>
                <div className={"row col-md"}>
                  <label>Marque : </label>
                  {data.OnduleurData.Marque}
                </div>
    
                <div className={"row col-md"}>
                  <label>Puissance : </label>
                  {data.OnduleurData.puissance}
                </div>
                <div className={"row col-md"}>
                  <label>Date de fabrication : </label>
                  {data.OnduleurData.date_de_fabrication}
                </div>
                <div className={"row col-md"}>
                  <label>Numéro de série /modèle : </label>
                  {data.OnduleurData.numero_serie}
                </div>
                <div className={"row col-md"}>
                  <label>Autonomie : </label>
                  {data.OnduleurData.Onduleur_autonomie}
                </div>
              </>
            )}
            {data?.CompresseursData && (
              <>
                <h6> Compresseurs </h6>
                <div className={"row col-md"}>
                  <label>Nombre : </label>
                  {data.CompresseursData.Nombre}
                </div>
                <div className={"row col-md"}>
                  <label>Utilisation : </label>
                  {data.CompresseursData.Utilisation}
                </div>
                <div className={"row col-md"}>
                  <label>Marque : </label>
                  {data.CompresseursData.Marque}
                </div>
    
                <div className={"row col-md"}>
                  <label>Puissance : </label>
                  {data.CompresseursData.puissance}
                </div>
                <div className={"row col-md"}>
                  <label>Date de fabrication : </label>
                  {data.CompresseursData.date_de_fabrication}
                </div>
                <div className={"row col-md"}>
                  <label>Numéro de série /modèle : </label>
                  {data.CompresseursData.numero_serie}
                </div>
                <div className={"row col-md"}>
                  <label>Pression : </label>
                  {data.CompresseursData.Pression}
                </div>
              </>
            )}
            {data?.ChaudieresData && (
              <>
                <h6> Chaudières </h6>
                <div className={"row col-md"}>
                  <label>Nombre : </label>
                  {data.ChaudieresData.Nombre}
                </div>
                <div className={"row col-md"}>
                  <label>Utilisation : </label>
                  {data.ChaudieresData.Utilisation}
                </div>
                <div className={"row col-md"}>
                  <label>Marque : </label>
                  {data.ChaudieresData.Marque}
                </div>
    
                <div className={"row col-md"}>
                  <label>Type : </label>
                  {data.ChaudieresData.Type}
                </div>
                <div className={"row col-md"}>
                  <label>Date de fabrication : </label>
                  {data.ChaudieresData.date_de_fabrication}
                </div>
                <div className={"row col-md"}>
                  <label>Numéro de série /modèle : </label>
                  {data.ChaudieresData.numero_serie}
                </div>
                <div className={"row col-md"}>
                  <label>Pression : </label>
                  {data.ChaudieresData.Pression}
                </div>
                <div className={"row col-md"}>
                  <label> Modèle : </label>
                  {data.ChaudieresData.Modèle}
                </div>
                <div className={"row col-md"}>
                  <label> Débit de vapeur : </label>
                  {data.ChaudieresData.debit_de_vapeur}
                </div>
                <div className={"row col-md"}>
                  <label> Température : </label>
                  {data.ChaudieresData.Température}
                </div>
              </>
            )}
            {data?.EauData && (
              <>
                <h6> Eau </h6>
                <div className={"row col-md"}>
                  <label>Source d'eau : </label>
                  {data.EauData.eau_source}
                </div>
                <div className={"row col-md"}>
                  <label>Nombre de bâche à eau : </label>
                  {data.EauData.eau_nb_bache}
                </div>
                <div className={"row col-md"}>
                  <label>Utilisation : </label>
                  {data.EauData.eau_utilisation}
                </div>
    
                <div className={"row col-md"}>
                  <label>Capacité : </label>
                  {data.EauData.Capacité}
                </div>
              </>
            )}
            {data?.StationPompageData && (
              <>
                <h6> Station de pompage </h6>
                <div className={"row col-md"}>
                  <label>Type: </label>
                  {data.StationPompageData.pompage_type}
                </div>
                <div className={"row col-md"}>
                  <label>Marque : </label>
                  {data.StationPompageData.Marque}
                </div>
                <div className={"row col-md"}>
                  <label>Modèle : </label>
                  {data.StationPompageData.Modele}
                </div>
    
                <div className={"row col-md"}>
                  <label>Puissance : </label>
                  {data.StationPompageData.puissance}
                </div>
                <div className={"row col-md"}>
                  <label>Date de fabrication : </label>
                  {data.StationPompageData.date_de_fabrication}
                </div>
                <div className={"row col-md"}>
                  <label>Débit : </label>
                  {data.StationPompageData.Débit}
                </div>
              </>
            )}
            {data?.GazData && (
              <>
                <h6> GAZ NATUREL </h6>
                <div className={"row col-md"}>
                  <label>Nombre de conduites : </label>
                  {data.GazData.nombre_de_conduites}
                </div>
                <div className={"row col-md"}>
                  <label>Positionnement des conduites : </label>
                  {data.GazData.gaz_naturel_position}
                </div>
                <div className={"row col-md"}>
                  <label>Pression : </label>
                  {data.GazData.Pression}
                </div>
    
                <div className={"row col-md"}>
                  <label>Utilisation : </label>
                  {data.GazData.Utilisation}
                </div>
                <div className={"row col-md"}>
                  <label>Implantation du poste de détente : </label>
                  {data.GazData.implantation_detente}
                </div>
                <div className={"row col-md"}>
                  <label>Vérification : </label>
                  {data.GazData.vérification}
                </div>
                <div className={"row col-md"}>
                  <label>Vanne d'arrêt d'urgence : </label>
                  {data.GazData.vanne_arret_urgence}
                </div>
              </>
            )}
            {data?.SystemeRefroidissementData && (
              <>
                <h6> Système de refroidissement </h6>
                <div className={"row col-md"}>
                  <label>Type : </label>
                  {data.SystemeRefroidissementData.refroidissement_type}
                </div>
                <div className={"row col-md"}>
                  <label>Utilisation : </label>
                  {data.SystemeRefroidissementData.Utilisation}
                </div>
                <div className={"row col-md"}>
                  <label>Localisation : </label>
                  {data.SystemeRefroidissementData.Localisation}
                </div>
                <div className={"row col-md"}>
                  <label>Marque : </label>
                  {data.SystemeRefroidissementData.Marque}
                </div>
    
                <div className={"row col-md"}>
                  <label>Numéro de série /modèle : </label>
                  {data.SystemeRefroidissementData.numero_serie}
                </div>
                <div className={"row col-md"}>
                  <label>Puissance : </label>
                  {data.SystemeRefroidissementData.puissance}
                </div>
                <div className={"row col-md"}>
                  <label>Date de fabrication : </label>
                  {data.SystemeRefroidissementData.date_de_fabrication}
                </div>
                <div className={"row col-md"}>
                  <label>Température : </label>
                  {data.SystemeRefroidissementData.Température}
                </div>
              </>
            )}
            {data?.StationTraitementEauData && (
              <>
                <h6> Station de traitement des eaux </h6>
                <div className={"row col-md"}>
                  <label>Nombre de station : </label>
                  {data.StationTraitementEauData.nombre_de_station}
                </div>
                <div className={"row col-md"}>
                  <label>Implantation : </label>
                  {data.StationTraitementEauData.Implantation}
                </div>
                <div className={"row col-md"}>
                  <label>Equipments alimentés par STE : </label>
                  {data.StationTraitementEauData.equipements_alimentes_ste}
                </div>
                <div className={"row col-md"}>
                  <label>Procédé : </label>
                  {data.StationTraitementEauData.Procédé}
                </div>
              </>
            )}
          </>
        );
      else return <>Didn't clock in</>;
};

export default Utilities;
