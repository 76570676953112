import React,{useEffect,useState} from "react";
import api from "../../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import Constants from "../../../Utils/Constants";
//"../../Utils/Constants";
const EditRecommendationOverlay = ({recommendation,type, close, reload,   ...props}) => {
  const [isLoading, setIsLoading] = useState(false); // Manage loading state for UI feedback
  
  useEffect(() => { 
    if (props.isLoading) {
      setIsLoading(props.isLoading);  
    }
  }, [props.isLoading]);  

  const submitRecommendationForm = async (e) => { // Use async/await for cleaner handling
    e.preventDefault();

    setIsLoading(true); // Set loading state to true
    console.log(`constant`,api)
    const formData = new FormData(e.target);
    let data = {};
 
    for (var [key, value] of formData.entries()) {
      data[key] = value;
    }
 

    try {
      const response = await api.post("/api/recommendation/edit", data); // Use await
console.log("resppp=>",response)
      if (response.data.success) {
        close();
        reload();
        props.setOverlay({
          message: "Recommendation modifié avec succés",
          label: "success",
          button: {
            type: "close",
            label: "OK",
            link: "javascript:void(0)",
          },
       
        });
        
      } else {
        // Handle API errors here
        console.error("API error:", response.data); // Log error details

        // Optionally display error message to user
      }
    } catch (err) {
      console.error("Error submitting form:", err); // Log errors
    } finally {
      setIsLoading(false); // Set loading state to false after request completes
    }
  };  
 
    return (
      <div className={"ui-overlay " + (props.open ? " open" : "")}>
        <div className={"ui-overlay-content site-overlay col-md-6"}>
          <button
            data-package-id="0"
            onClick={(e) => {
              close(e);
            }}
            className={"ui-overlay-close btn btn-danger btn-sm"}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>

          <h5 className={"mb-3"}>Fiche du Recommendation</h5>

          <form
              method="post"
              encType={"multipart/form-data"}
              onSubmit={submitRecommendationForm} >
              <div className="form-group mt-3">
                <input hidden name="id" value={recommendation.id} />
                <label className={"font-exp"}>
                  Edit Recommendation
                  
                    
                   
                </label>

                 
                <Select
                  name={"type"}
                  placeholder={recommendation.type || 'default_value' }
                  options={type} 
                  className={"mt-2"}
                />
    <div className={"form-group"}>
            <label>Description</label>
            <textarea
              name="name"
              className={"form-control"}
              id="name"
              defaultValue={recommendation.name}
            />
          </div>
                <input
                  type="submit"
                  value="Envoyer"
                  className={"btn btn-primary btn-sm mt-5"}
                />
              </div>
            </form>
        </div>
      </div>
    );
  
}

export default EditRecommendationOverlay;
